<template>
  <div class="sm:px-[4%] lg:w-[1280px] mx-auto font-basic text-text-1 py-10 overflow-auto">
    <p class="lg:text-medium-size sm:text-content-size font-bold">이용약관</p>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 1조 [목적]</p>
      <p class="lg:mt-10 sm:mt-5">본 약관은 ㈜리드워크(이하 “회사”라고 합니다)가 운영하는 겟아이즈 모바일 앱을 통해 제공하는 전자상거래 서비스(이하 “서비스”라고 합니다)를 회원이 이용함에
        있어 회사와 회원간의 권리·의무 및 책임사항을 규정함을 목적으로 합니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 2조 [용어의 정의]</p>
      <p class="mt-4 lg:text-[18px]">1. 본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 겟아이즈라 함은 회사가 상품 또는 용역(이하 상품이라 함)을 회원에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 상품을 거래할 수 있도록
        설정한 겟아이즈 모바일 앱상의 가상의 영업장을 말합니다.</p>
      <p class="mt-4 lg:text-[18px]">3. “회원”이라 함은 겟아이즈에 회원등록을 한 자로서, 본 약관에 따라 계속적으로 회사가 제공하는 서비스를 이용하는 자를 말합니다</p>
      <p class="mt-4 lg:text-[18px]">4. “할인쿠폰”이라 함은 상품을 구매할 때 쿠폰에 표시된 금액 또는 비율만큼 구매가격을 할인 받을 수 있는 회사 전용의 할인권을 말합니다.</p>
      <p class="mt-4 lg:text-[18px]">5. “포인트”라 함은 회사가 회원의 구매활동, 리뷰작성, 이벤트 참여, 배송지연보상 등에 따라 회원에게 지급하는 것으로 회원이 서비스를 이용할 때
        현금처럼 사용할 수 있는 회사 전용의 사이버 화폐를 말합니다.</p>
      <p class="mt-4 lg:text-[18px]">6. “게시물”이라 함은 회원이 서비스를 이용함에 있어서 겟아이즈에 게시한 부호, 문자, 음성 형태의 글, 사진, 동영상 및 각종 파일과 링크 등을
        말합니다.</p>
      <p class="mt-4 lg:text-[18px]">7. 전항 각호에 해당하는 정의 이외의 기타 용어의 정의에 대하여는 거래 관행 및 관계 법령에 따릅니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 3조 [약관의 게시와 개정]</p>
      <p class="mt-4 lg:text-[18px]">1. 회사는 본 약관의 내용과 상호 및 대표자의 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 사업자등록번호, 연락처,
        이메일 통신판매업 신고번호, 개인정보관리책임자 등을 회원이 쉽게 확인할 수 있도록 서비스 초기화면에 게시합니다. 다만 약관의 내용은 회원이 연결화면을 통하여 볼 수 있도록 할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회사는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」,
        전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」, 「위치정보의 보호 및 이용 등에 관한 법률」 등 관련법을 위배하지 않는 범위에서 본
        약관을 개정할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">3. 회사가 본 약관을 개정할 경우에는 약관의 개정 내용 및 적용일자를 명시하여 현행 약관과 함께 서비스 초기화면에 최소 그 적용일자 7일 이전부터
        적용일자 전일까지 공지합니다. 다만, 약관이 회원에게 불리하게 개정되거나 회원의 권리·의무에 중대한 영향을 주는 경우에는 적용일자로부터 30일 전부터 공지합니다.</p>
      <p class="mt-4 lg:text-[18px]">4. 회사가 전항에 따른 기간 동안 개정 약관을 공지하면서 회원에게 해당 기간 내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게
        밝혔음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회사는 회원이 개정 약관에 동의한 것으로 간주합니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 4조 [약관의 해석]</p>
      <p class="mt-4 lg:text-[18px]">1. 본 약관에 명시되지 아니한 사항과 본 약관의 해석에 관하여는 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제 등에 관한 법률」,
        공정거래위원회가 정하는 「전자상거래 등에서의 소비자 보호지침」 등 관계법령과 상관례에 따릅니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회사는 필요한 경우 특정 서비스에 대하여 개별 약관 또는 운영정책을 정할 수 있으며, 그 내용이 본 약관과 충돌하는 경우 별도의 약관이 우선하여
        적용됩니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 5조 [회원가입]</p>
      <p class="mt-4 lg:text-[18px]">1. 회원가입은 회원이 되고자 하는 자가 회사가 정한 절차에 따라 회원가입 신청을 하고 회사가 이러한 신청에 대하여 승낙함으로써 성립합니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회사는 회원이 되고자 하는 자의 신청에 대하여 회원가입을 승낙함을 원칙으로 합니다. 다만, 회사는 다음 각 호에 해당하는 신청에 대하여는 승낙을
        하지 않거나 사후에 회원가입 승낙을 철회하여 서비스 이용을 제한할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]"> - 타인의 정보를 도용하여 신청한 경우</p>
      <p class="mt-4 lg:text-[18px]"> - 등록 내용에 허위, 기재 누락, 오기가 있는 경우</p>
      <p class="mt-4 lg:text-[18px]"> - 이전에 동일∙유사한 아이디 또는 다른 아이디를 통하여 부정한 사용을 한 이력이 있거나 의심되는 경우</p>
      <p class="mt-4 lg:text-[18px]"> - 만14세 미만의 아동이 부모 등 법정대리인의 동의를 얻지 아니하고 회원가입 신청을 하는 경우</p>
      <p class="mt-4 lg:text-[18px]">3. 회사에 의하여 서비스 이용이 제한된 날로부터 3개월 이내에 재이용 신청을 하는 경우 다만, 회사의 회원 재가입 승낙을 얻은 경우에는 예외로 합니다.
      </p>
      <p class="mt-4 lg:text-[18px]">4. 부정한 용도 또는 영리를 추구할 목적으로 서비스를 이용하고자 하는 경우 다만, 회사가 승인한 범위 내에서의 서비스 이용은 예외로 합니다.</p>
      <p class="mt-4 lg:text-[18px]"> - 관련법령에 위배되거나 사회의 안녕질서 혹은 미풍양속을 저해할 수 있는 목적으로 신청한 경우</p>
      <p class="mt-4 lg:text-[18px]"> - 기타 본 약관에 위배되거나 위법 또는 부당한 이용신청임이 확인된 경우</p>
      <p class="mt-4 lg:text-[18px]">5. 제1항에 따른 신청에 있어 회사는 회원의 확인을 위해 전문기관을 통한 인증을 요구할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">6. 회사는 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 장애사유가 있는 경우에는 그 사유가 해소될 때까지 이용 승낙을 유보할 수 있습니다.
      </p>
      <p class="mt-4 lg:text-[18px]">7. 회사가 회원가입 신청을 유보하거나 승낙을 하지 아니한 경우 회사는 원칙적으로 이를 회원이 되고자 하는 자에게 통보합니다.</p>
      <p class="mt-4 lg:text-[18px]">8. 회원가입의 성립 시기는 회사가 가입완료를 신청 절차 상에 표시한 시점으로 합니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 6조 [이용계약의 종료]</p>
      <p class="mt-4 lg:text-[18px]">1. 회원은 언제든지 내 정보 관리 메뉴의 [회원탈퇴] 버튼 등을 통하여 이용계약 해지 신청을 할 수 있으며, 회사는 관련법 등이 정하는 바에 따라 이를
        즉시 처리하여야 합니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회원이 계약을 해지할 경우, 관련법 및 개인정보처리방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 개인정보 및
        서비스이용기록(회원이 이메일 ID에 소셜 계정을 연결한 경우 그 연결 정보 포함)을 복구할 수 없는 방법으로 지체 없이 삭제합니다. 따라서 회원은 필요한 데이터를 미리 백업하여야 하며, 회사는 삭제된
        개인정보 및 서비스이용기록에 대하여 책임을 지지 아니합니다.</p>
      <p class="mt-4 lg:text-[18px]">3. 회사는 회원이 이 약관의 의무를 위반하거나 서비스의 정상적인 운영을 방해한 경우, 경고, 일시정지, 영구이용정지 등으로 서비스 이용을 단계적으로
        제한할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">4. 회원은 제3항에 따른 서비스 이용정지 기타 서비스 이용과 관련된 이용제한에 대해 이의신청을 할 수 있으며, 회사는 회원의 이의신청이 정당하다고
        판단되는 경우 즉시 서비스 이용을 재개합니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 7조 [회원정보의 변경]</p>
      <p class="mt-4 lg:text-[18px]">1. 회원은 [마이페이지] 메뉴를 통하여 언제든지 본인의 개인정보를 열람하고 수정할 수 있습니다. 다만, 서비스 관리를 위해 필요한 아이디, 닉네임 등
        회사가 수정을 불허하는 항목의 수정은 회사와 사전 협의가 필요합니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 상당한 기간 이내에 회사에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
      </p>
      <p class="mt-4 lg:text-[18px]">3. 제1항의 변경사항을 회사에게 알리지 않아 발생한 불이익에 대하여 회사에서는 책임지지 않습니다.</p>
      <p class="mt-4 lg:text-[18px]">4. 회원은 변경사항과 관련하여 회사가 요청하는 경우 즉시 변경사항에 관한 증빙자료를 제공하여야 합니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 8조 [아이디 및 비밀번호 관리]</p>
      <p class="mt-4 lg:text-[18px]">1. 회원의 아이디와 비밀번호에 관한 관리책임은 회원에게 있으며 이를 제3자가 이용하게 하여서는 안 됩니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회원은 아이디 및 비밀번호의 관리상 불충분, 사용상의 과실, 제3자의 사용 등에 의한 불이익에 대하여 책임을 집니다.</p>
      <p class="mt-4 lg:text-[18px]">3. 회원은 아이디 및 비밀번호가 도용되거나 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 회사에 통지하고 회사의 별도 지시가 있는 경우 그에
        따라야 합니다</p>
      <p class="mt-4 lg:text-[18px]">4. 위 각 항을 따르지 않아 발생한 불이익에 대하여 회사는 책임을 지지 않습니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 9조 [회원에 대한 통지]</p>
      <p class="mt-4 lg:text-[18px]">1. 회사가 회원에 대한 통지를 하는 경우 본 약관에 별도 규정이 없는 한 회원가입 시 회원이 제출한 이메일, SMS 등으로 할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회사는 불특정 다수의 회원에 대한 통지의 경우, 회사는 1주일 이상 서비스 초기화면에 게시하여 제1항의 통지에 갈음할 수 있습니다. 다만, 회원
        본인의 거래에 관하여 중대한 영향을 미치는 사항에 대하여는 개별 통지합니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 10조 [개인정보 보호 의무]</p>
      <p class="mt-4 lg:text-[18px]">회사는 「개인정보보호법」 등 관계 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다.<br><br>
        개인정보의 보호 및 사용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다. 다만, 회사의 공식 웹사이트 이외의 링크된 사이트에서는 회사의 개인정보 처리방침이 적용되지 않습니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 11조 [회사의 의무]</p>
      <p class="mt-4 lg:text-[18px]">1. 회사는 관련 법령과 이 약관이 금지하거나 미풍양속에 반하는 행위를 하지 않으며, 계속적이고 안정적으로 서비스를 제공하기 위하여 최선을 다하여
        노력합니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함) 보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고
        준수합니다.</p>
      <p class="mt-4 lg:text-[18px]">3. 회사는 서비스 이용과 관련하여 발생하는 이용자의 불만 또는 피해구제요청을 적절하게 처리합니다.</p>
      <p class="mt-4 lg:text-[18px]">4. 회사는 서비스 이용과 관련하여 회원으로부터 제기되는 의견이나 불만이 정당하다고 객관적으로 인정될 경우에는 적절한 절차를 거쳐 이를 처리하여야
        합니다. 회원이 제기한 의견이나 불만사항에 대해서는 게시판을 활용하거나 전자우편, 문자메시지 등을 통하여 회원에게 처리과정 및 결과를 전달합니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 12조 [회원의 의무]</p>
      <p class="mt-4 lg:text-[18px]">1. 회원은 다음 행위를 하여서는 안 됩니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 게시물 등록 시의 허위 또는 타인의 저작권을 침해한 내용으로 작성</p>
      <p class="mt-4 lg:text-[18px]">3. 기타 게시물의 등록, 변경 시 허위내용의 작성</p>
      <p class="mt-4 lg:text-[18px]">4. 아이디, 실명, 닉네임 등을 포함한 타인의 개인정보도용</p>
      <p class="mt-4 lg:text-[18px]">5. 회사가 게시한 정보의 변경</p>
      <p class="mt-4 lg:text-[18px]">6. 회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시</p>
      <p class="mt-4 lg:text-[18px]">7. 회사와 기타 제3자의 저작권 등 지적재산권에 대한 침해</p>
      <p class="mt-4 lg:text-[18px]">8. 회사 및 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위</p>
      <p class="mt-4 lg:text-[18px]">9. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위</p>
      <p class="mt-4 lg:text-[18px]">10. 회사의 동의 없이 영리를 목적으로 서비스를 사용하는 행위</p>
      <p class="mt-4 lg:text-[18px]">11. 기타 불법적이거나 부당한 행위</p>
      <p class="mt-4 lg:text-[18px]">12. 회원은 서비스를 이용하여 얻은 정보를 회사의 사전동의 없이 복사, 복제, 번역, 출판, 방송 기타의 방법으로 사용하거나 이를 타인에게 제공 및
        공유할 수 없습니다</p>
      <p class="mt-4 lg:text-[18px]">13. 회원은 관계법, 이 약관의 규정, 이용안내 및 서비스와 관련하여 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에
        방해되는 행위를 하여서는 안됩니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 13조 [서비스의 내용]</p>
      <p class="mt-4 lg:text-[18px]">회사가 회원에게 제공하는 서비스의 내용은 다음 각호와 같습니다.</p>
      <p class="mt-4 lg:text-[18px]">1. 전자상거래 및 플랫폼 제공 서비스</p>
      <p class="mt-4 lg:text-[18px]">2. 판매관련 업무지원 서비스</p>
      <p class="mt-4 lg:text-[18px]">3. 구매관련 지원 서비스</p>
      <p class="mt-4 lg:text-[18px]">4. 계약체결 및 결제 지원 서비스</p>
      <p class="mt-4 lg:text-[18px]">5. 상품검색 정보제공 서비스</p>
      <p class="mt-4 lg:text-[18px]">6. 선물하기 서비스</p>
      <p class="mt-4 lg:text-[18px]">7. 기타 전자상거래 관련 서비스</p>
      <p class="mt-4 lg:text-[18px]">8. 광고 및 프로모션 서비스</p>
      <p class="mt-4 lg:text-[18px]">9. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 서비스에 공개 또는 게시하는 행위</p>
      <p class="mt-4 lg:text-[18px]">10. 회사 또는 회사가 제휴사와 공동으로 제공하는 프로모션, 이벤트 등</p>
      <p class="mt-4 lg:text-[18px]">11. 기타 서비스</p>
      <p class="mt-4 lg:text-[18px]">12. 상품평, 전문가 리뷰 등 기타 정보제공 서비스</p>
      <p class="mt-4 lg:text-[18px]">13. 상품문의 게시판 서비스</p>
      <p class="mt-4 lg:text-[18px]">14. 기타 회사가 추가 개발하거나 다른 회사와의 제휴계약 등을 통해 회원에게 제공하는 일체의 서비스</p>
      <p class="mt-4 lg:text-[18px]">회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용가능시간을 별도로 지정할 수 있습니다. 다만, 이러한 경우에는 그 내용을 사전에
        공지합니다.<br><br>
        서비스는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.<br><br>
        회사는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 서비스의 제공을 일시적으로 중단할 수 있습니다. 이 경우 회사는 제9조 [회원에 대한 통지]에 정한
        방법으로 회원에게 통지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
        <br><br>
        회사는 서비스의 제공에 필요한 경우 정기점검을 실시할 수 있으며, 정기점검 시간은 서비스제공화면에 공지한 바에 따릅니다.
      </p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 14조 [서비스의 변경]</p>
      <p class="mt-4 lg:text-[18px]">1. 회사는 운영상, 기술상의 필요에 따라 제공하는 서비스의 전부 또는 일부를 변경할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 서비스의 내용, 이용방법, 이용시간에 대하여 변경이 있는 경우 회사는 제3조 제3항에 따라 변경사유, 변경될 서비스의 내용 및 제공일자 등을 변경
        7일 전(변경 내용이 회원에게 불리하거나, 권리·의무에 중대한 영향을 주는 경우에는 변경 30일 전) 해당 서비스 초기화면 또는 공지사항 게시판에 게시하여야 합니다.</p>
      <p class="mt-4 lg:text-[18px]">3. 회사는 무료로 제공되는 서비스의 일부 또는 전부에 대한 수정, 중단, 변경에 대하여 관련법에 특별한 규정이 없는 한 회원에게 별도의 보상을 하지
        않습니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 15조 [서비스의 종료]</p>
      <p class="mt-4 lg:text-[18px]">1. 회사는 서비스를 종료하고자 하는 날로부터 3개월 이전에 이 약관 제9조[회원에 대한 통지]에 따라 통지합니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 본 조 제1항에 따른 서비스 종료 통지를 받은 회원은 서비스 이용을 통해 획득한 혜택 등을 회사가 별도 지정하는 날(이하 “서비스 종료일”이라
        함)까지 이 약관이 정하는 바에 따라 소진해야 하고, 서비스 종료일까지 사용하지 않은 혜택은 모두 소멸되며 모두 소멸되며, 회사는 이에 대해 별도로 보상하지 않습니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 16조 [정보의 제공 및 광고의 게재]</p>
      <p class="mt-4 lg:text-[18px]">1. 회사는 서비스 이용 중 필요하다고 인정되는 각종 정보를 서비스 화면에 게재하거나 이메일, SMS 등의 방법으로 회원에게 제공할 수 있습니다. 다만,
        회원은 관련법에 따른 거래관련 정보 및 고객문의 등에 대한 답변 등을 제외하고는 언제든지 전자우편에 대해서 그 수신 거절을 할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회사는 서비스 개선 및 소개 등의 목적으로 회원의 동의 하에 추가적인 개인 정보를 요구할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">3. 회사는 서비스 제공과 관련하여 서비스 화면, 가입 시 회원이 제공한 이메일 등에 광고를 게재할 수 있고, 회원은 서비스 화면에 노출되는 광고 게재에
        동의합니다. 광고가 게재된 이메일 등을 수신한 회원은 수신 거절을 할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">4. 회원이 서비스 상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로
        회원과 광고주 간의 문제이고, 회사는 광고의 내용을 보증하지 않습니다. 만약 회원과 광고주간에 문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와 관련하여 회사가 광고주의 위법 행위에 적극
        가담하였다거나, 위법행위를 고의 또는 중과실로 방치하였다는 등의 사정이 없는 한 회사는 어떠한 책임도 부담하지 않습니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 17조 [게시물의 작성 및 관리, 임시조치 등]</p>
      <p class="mt-4 lg:text-[18px]">1. 회원이 작성한 게시물에 대한 모든 권리 및 책임은 이를 게시한 회원에게 있으며, 회사는 게시물의 신뢰성, 진실성, 정확성 등에 대해서 책임지지
        않으며 보증하지 않습니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회원의 게시물이 타인의 저작권을 침해함으로써 발생하는 민, 형사상의 책임은 전적으로 회원이 부담하여야 합니다.</p>
      <p class="mt-4 lg:text-[18px]">3. 회사는 회원이 서비스 내에 게시한 게시물을 서비스의 판촉, 홍보, 안내 등의 목적으로 타 사이트에 복제, 배포, 전송, 전시할 수 있으며, 본질적인
        내용에 변경을 가하지 않는 범위에서 수정, 편집할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">4. 회사는 회원이 게시하거나 등록하는 게시물이 다음 각 항에 해당한다고 판단되는 경우에 사전통지 없이 삭제할 수 있고, 이에 대하여 회사는 어떠한
        책임도 지지 않습니다.</p>
      <p class="mt-4 lg:text-[18px]">5. 이 약관에 위배되거나 상용 또는 불법, 음란, 저속하다고 판단되는 게시물을 게시한 경우</p>
      <p class="mt-4 lg:text-[18px]">6. 회사의 원활한 서비스 제공을 방해하거나 서비스 성격에 부합하지 않는 정보의 경우</p>
      <p class="mt-4 lg:text-[18px]">7. 공공질서 및 미풍양속에 위반되는 내용인 경우</p>
      <p class="mt-4 lg:text-[18px]">8. 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키고 피해를 주는 내용인 경우</p>
      <p class="mt-4 lg:text-[18px]">9. 제3자의 저작권, 지적 재산권, 초상권 등 권리를 침해하는 내용인 경우</p>
      <p class="mt-4 lg:text-[18px]">10. 해당 장소 및 콘텐츠 내용과 관련 없는 경우</p>
      <p class="mt-4 lg:text-[18px]">11. 타사의 광고, 홍보 등의 상업적인 내용인 경우</p>
      <p class="mt-4 lg:text-[18px]">12. 범죄적 행위에 결부된다고 인정되는 내용인 경우</p>
      <p class="mt-4 lg:text-[18px]">13. 기타 관계 법령에 위배되는 경우</p>
      <p class="mt-4 lg:text-[18px]">14. 회원이 작성한 게시물에 회사가 지정한 부적절한 단어가 포함된 경우, 별도의 안내없이 게시물의 게재가 거절 될 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">15. 회원의 게시물 등으로 인한 법률상 이익 침해를 근거로, 다른 회원 또는 제3자가 회원 또는 회사를 대상으로 하여 민·형사상의 법적조치(예:
        형사고소, 가처분 신청·손해배상청구 등 민사소송의 제기)를 취하는 경우, 회사는 법적 조치의 결과인 법원의 확정판결이 있을 때까지 관련게시물 등에 대한 접근을 잠정적으로 제한하는 임시조치를 취할 수
        있습니다. 게시물 등의 접근제한과 관련한 법적조치의 소명, 법원의 확정판결에 대한 소명책임은 게시물 등에 대한 조치를 요청하는 자가 부담합니다.</p>
      <p class="mt-4 lg:text-[18px]">16. 회사는 서비스 내에 게시된 게시물 등이 사생활 침해 또는 명예훼손 등 제3자의 권리를 침해한다고 인정하는 경우 회원 또는 제3자의 신고가 없는
        경우에도 임시조치를 취할 수 있습니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 18조 [저작권의 귀속 및 이용제한]</p>
      <p class="mt-4 lg:text-[18px]">회원이 서비스 내에 게시한 게시물은 「저작권법」에 의해 보호를 받습니다. 다만 회사의 서비스에 대한 저작권 및 지적재산권은 회사에
        귀속됩니다.<br><br>
        회원은 자신의 게시물을 회사가 국내외에서 다음 각 호의 어느 하나의 목적으로 사용하는 것을 허락합니다.<br><br>
        회사의 서비스 또는 회사의 공식 웹사이트 이외의 회사의 업무와 연계된 사이트 내에서 회원의 게시물의 복제, 전송, 전시 및 우수 게시물을 회사의 업무와 연계된 사이트 화면 등에 노출하기 위하여 회원
        게시물의 크기를 변환하거나 이미지 변경, 단순화하는 등의 방식으로 수정하는 것
        <br><br>
        회사의 서비스를 홍보하기 위한 목적으로 미디어, 언론기관 등에게 회원의 게시물 내용을 보도, 방영하게 하는 것. 단, 이 경우 회사는 회원의 개별 동의 없이 미디어, 언론기관 등에게 개인정보를 제공하지
        않습니다.
        <br><br>
        전항의 규정에도 불구하고 회사가 회원의 게시물을 전항 각 호의 어느 하나에 기재된 목적 이외의 방법으로 사용하는 경우에는 사전에 해당 회원으로부터 동의를 얻어야 합니다. 게시물에 대한 회사의 사용 요청,
        회원의 동의 및 동의철회는 전화, 이메일, SMS 등 회사가 요청하는 방식에 따릅니다. 이 경우 회사는 게시물의 출처를 표시합니다.
        <br><br>
        회원이 서비스의 이용계약을 해지하더라도 회원의 명의로 서비스에 등록한 게시물은 삭제되지 않습니다. 회원은 자신의 게시물이 회원 탈퇴 후 서비스에서 이용되는 것을 원하지 않는 경우 회원 탈퇴 전에 직접
        게시물을 삭제하여야 합니다. (단, 제3자가 다시 게시하거나 저장한 게시물은 삭제되지 않을 수 있습니다.)
        <br><br>
        회사는 서비스 정책 또는 회사가 운영하는 서비스 간 통합, 합병, 영업양도 등의 사유로 게시물의 게재 위치를 변경·이전할 수 있으며, 이 경우 사전에 공지합니다.
        <br><br>
        회원은 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
      </p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 19조 [매매계약의 체결 등]</p>
      <p class="mt-4 lg:text-[18px]">회사는 판매회원과 구매회원 간 상품 등의 구매를 연결해주는 중개 서비스를 제공할 수 있습니다.
        <br><br>
        상품 등에 대한 매매계약은 판매회원이 제시한 상품 등의 판매조건에 응하여 구매회원이 청약의 의사표시를 하고 이에 대하여 판매회원이 승낙의 의사표시를 함으로써 체결됩니다.
        <br><br>
        회사는 구매회원이 현금, 카드 기타의 방법으로 매매 대금을 결제할 수 있는 방법을 제공합니다. 매매 대금의 결제와 관련하여 구매회원이 입력한 정보 및 그 정보와 관련하여 발생한 책임과 불이익은 전적으로
        구매회원이 부담하여야 합니다.
        <br><br>
        상품을 주문한 후 일정 기간 내에 매매대금을 결제하지 않을 경우 회사는 당해 주문을 구매회원의 동의 없이 취소할 수 있습니다.
        <br><br>
        회사는 구매회원의 상품 매매계약 체결 내용을 회원정보 - 주문내역을 통해 확인할 수 있도록 조치하며, 매매계약의 취소 방법 및 절차를 안내합니다.
        <br><br>
        회사는 구매회원이 매매대금 결제 시 사용한 결제수단에 대해 정당한 사용권한을 가지고 있는지 여부를 확인할 수 있으며, 이에 대한 확인이 완료될 때까지 거래진행을 중지하거나, 확인이 불가한 해당거래를 취소할
        수 있습니다.
      </p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 20조 [배송]</p>
      <p class="mt-4 lg:text-[18px]">1. 배송 소요기간은 입금확인일 익일을 기산일로 하여 배송완료 되기까지의 기간을 말합니다. 공휴일 및 기타 휴무일 또는 천재지변 등 불가항력적인 사유가
        발생한 경우 그 해당기간은 배송 소요기간에서 제외됩니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 회사는 판매회원에게 회사로부터 구매회원의 입금 또는 대금결제에 대한 확인통지를 받은 후 3영업일 이내에 배송에 필요한 조치를 취하도록 안내합니다.
      </p>
      <p class="mt-4 lg:text-[18px]">3. 회사는 상품 등의 배송과 관련하여 판매회원과 구매회원, 배송업체, 금융기관 등과의 사이에 발생한 분쟁은 당사자들 간의 해결을 원칙으로 하며, 회사는
        어떠한 책임도 부담하지 않습니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 21조 [상품 등 구매취소, 교환 및 반품]</p>
      <p class="mt-4 lg:text-[18px]">구매회원은 상품이 발송되기 전까지 구매를 취소할 수 있으며, 배송 중이면 취소가 아닌 반품절차에 의해 처리됩니다. 배송준비 중 상태에서는 즉시 취소처리가
        완료되는 것이 원칙이나 이미 상품이 발송되었다면 발송된 상품의 왕복 배송비는 구매회원이 부담해야 합니다.
        <br><br>
        회사는 구매회원으로부터 구매취소의 의사표시를 접수하면, 즉시 그러한 사실을 판매회원에게 통보합니다.
        <br><br>
        구매회원이 상품 수령 후 교환이나 환불을 요청하면 판매회원은 「전자상거래 등에서의 소비자보호에 관한 법률」 등 관련 법률에 따라 반품을 받은 후 교환이나 환불을 해주어야 하며, 추가로 발생하는 비용은
        구매회원과 판매회원 중 교환이나 환불의 책임이 있는 측에서 부담합니다.
        구매회원은 배송완료일로부터 7일 이내에 반품 또는 교환을 요청할 수 있습니다. 다만, 다음 각 호의 어느 하나에 해당하는 경우에는 그러하지 아니합니다.
        <br><br>
        구매회원의 책임 있는 사유로 상품 등이 멸실 또는 훼손된 경우
        <br><br>
        구매회원의 사용 또는 일부 소비로 인하여 상품 등의 가치가 현저히 감소한 경우
        <br><br>
        시간의 경과에 의하여 재판매가 곤란할 정도로 상품 등의 가치가 현저히 감소한 경우
        <br><br>
        복제 가능한 상품의 포장을 훼손한 경우
        <br><br>
        그 밖에 법령에 따른 반품 제한 사유에 해당되는 경우
        <br><br>
        판매회원은 「전자상거래 등에서의 소비자보호에 관한 법률」 제17조 제2항에 따라 청약철회가 제한되는 사유에 해당되는 경우 청약철회가 불가능하다는 사실을 「전자상거래 등에서의 소비자보호에 관한 법률」
        제17조 제6항에 따라 상품의 포장 기타 소비자가 쉽게 알 수 있는 곳에 명기하여야 합니다.
        제3항에도 불구하고, 구매회원은 배송된 상품이 표시 또는 광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 상품배송일로부터 90일 이내, 그 사실을 안 날 또는 알 수 있었던 날로부터 30일 이내에
        해당 상품의 반품 또는 교환을 요청할 수 있습니다.
        <br><br>
        배송상의 문제로 인해 구매회원이 입은 손해에 대한 책임은 책임의 소재를 발생시킨 해당 배송업체를 지정한 판매회원에게 있습니다.<br>
        상품 등의 매매와 관련하여 발생하는 상품의 배송, 청약철회, 반품 등의 거래진행은 거래 당사자인 판매회원과 구매회원 간에 수행되어야 하며, 회사는 거래진행에 관여하지 않습니다.<br>
      </p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 22조 [선물하기 서비스의 이용]</p>
      <p class="mt-4 lg:text-[18px]">1. 선물하기 서비스를 통하여 상품 등을 구매하는 구매회원(이하 “선물구매자”라 합니다)은 상품 등을 수령할 수 있는 수신자(이하 “선물수신자”라
        합니다)를 지정하여야 합니다.</p>
      <p class="mt-4 lg:text-[18px]">2. 선물수신자는 선물구매자의 구매대금 지급 완료일로부터 7일 내에 배송지 주소를 입력하여 상품 등을 수령할 수 있습니다.</p>
      <p class="mt-4 lg:text-[18px]">3. 선물수신자가 입력한 배송지 주소를 취소 또는 변경하고자 하는 경우 선물구매자를 통하여 회사에게 별도 요청하여야 합니다.</p>
      <p class="mt-4 lg:text-[18px]">4. 선물수신자가 선물구매자의 구매대금 지급 완료일로부터 7일 내에 배송지 주소를 입력하지 않은 경우, 해당 주문은 자동 취소 처리되며 이에 따른 환불은
        선물수신자가 아닌 선물구매자에게 이루어 집니다.</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 23조 [대리행위 및 보증의 부인 진행예정]</p>
      <p class="mt-4 lg:text-[18px]">회사는 통신판매중개자로서 효율적인 서비스를 위한 시스템 운영 및 관리 책임만을 부담하며, 상품 등의 거래와 관련하여 구매회원 또는 판매회원을 대리하지
        아니하고, 회원 사이에 성립된 거래 및 회원이 제공하고 등록한 정보에 대해서는 해당 회원이 그에 대한 직접적인 책임을 부담하여야 합니다.
        <br><br>
        회사는 회사가 제공하는 시스템을 통하여 이루어지는 구매회원과 판매회원 간의 거래와 관련하여 판매의사 또는 구매의사의 존부 및 진정성, 등록물품의 품질, 완전성, 안정성, 적법성 및 타인의 권리에 대한
        비침해성, 구매회원 또는 판매회원이 입력하는 정보 및 그 정보를 통하여 링크된 URL에 게재된 자료의 진실성 또는 적법성 등 일체에 대하여 보증하지 아니하며, 이와 관련한 일체의 위험과 책임은 그러한
        거래의 당사자가 전적으로 부담합니다.
      </p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 24조 [손해배상]</p>
      <p class="mt-4 lg:text-[18px]">회사 또는 구매회원의 귀책사유로 인하여 상대방 또는 제3자에게 손해가 발생한 경우 귀책 당사자는 해당 손해를 배상해야 합니다.
        <br><br>
        구매회원이 이 약관의 규정 및 관련법 등을 위반하여 회사에 손해가 발생한 경우 회사는 회원에 대하여 손해배상을 청구할 수 있습니다. 이 경우 구매회원은 고의, 과실이 없음을 입증하지 못하는 경우 책임을
        면할 수 없습니다.
      </p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 25조 [책임 제한]</p>
      <p class="mt-4 lg:text-[18px]">회사는 약관, 서비스 이용 방법 및 이용 기준을 준수하지 않는 등 회원의 귀책사유로 인한 서비스 이용의 장애에 대한 책임을 지지 않습니다.
        <br><br>
        회사는 제13조 제4항의 사유로 인하여 서비스를 일시적으로 중단하는 경우, 이로인해 회원 또는 제3자가 입은 손해에 대하여 책임지지 않습니다. 단, 회사의 고의 중과실로 인한 경우에는 그러하지 않습니다.
        <br><br>
        회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책, 운영상의 긴요한 사유로 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법령에 별도 규정이 있지 아니하는 한 별도의 보상을 하지
        않습니다.
        <br><br>
        회사는 다음 각 호의 사유로 인하여 회원 또는 제3자에게 발생한 손해에 대하여는 그 책임을 지지 아니합니다.
        <br><br>
        천재지변, 전쟁, 기간통신사업자의 서비스 중지 등 해결이 곤란한 기술적 결함 기타 불가항력으로 인해 서비스를 제공할 수 없는 경우<br>
        회원이 자신의 아이디 또는 비밀번호 등의 관리를 소홀히 한 경우<br>
        회사의 관리영역이 아닌 공중통신선로의 장애로 서비스 이용이 불가능한 경우<br>
        기타 회사의 귀책사유가 없는 통신서비스 등의 장애로 인한 경우<br>
        회사는 회원 또는 제3자가 서비스 내 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해 보증하지 않으며, 회사 또는 회사 소속 임직원의 고의 또는 중대한 과실이 없는 한 책임이
        면제됩니다.
        <br><br>
        회사는 회원 상호간 또는 회원과 제3자 상호 간에 서비스를 매개로 발생한 분쟁에 대해 개입할 의무가 없습니다. 이로 인한 손해가 발생한 경우 회사의 고의 또는 중대한 과실이 없는 한 책임이 면제됩니다.
        <br><br>
        회사의 서비스 내에서 링크, 배너 등을 통하여 연결된 회사(이하 피연결회사)와 회원 간에 이루어진 거래에 회사는 개입하지 않으며, 해당 거래에 대하여 책임을 지지 않습니다.
        <br><br>
        회사는 회원이 서비스를 이용하여 기대하는 효용을 얻지 못한 것에 대하여 책임을 지지 않으며 서비스에 대한 취사 선택 또는 이용으로 발생하는 손해 등에 대해서도 책임이 면제됩니다.
      </p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">제 26조 [관할법원]</p>
      <p class="mt-4 lg:text-[18px]">회사와 회원 간에 제기된 법적 분쟁은 대한민국 법을 준거법으로 합니다.
        <br><br>
        회사와 회원 간의 분쟁에 관한 소송은 서울중앙지방법원 또는 민사소송법 상의 관할법원에 제소합니다.
      </p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">부칙</p>
      <p class="mt-4 lg:text-[18px]">공고일자 : 2023년 9월 7일</p>
      <p class="mt-4 lg:text-[18px]">시행일자 : 2023년 9월 14일</p>
    </div>

    <div class="lg:mt-10 sm:mt-5">
      <p class="font-bold lg:text-content-size sm:text-small-size">회사는 개인정보 처리방침을 변경하는 경우에는 변경 및 시행의 시기, 변경된 내용을 지속적으로
        공개합니다.</p>
    </div>

  </div>
</template>
<script>
export default {

}
</script>
