<template>
  <Header />
  <div class="w-full font-basic text-text-0 mt-28">

    <!-- 테스트 컨설팅 -->
    <div class="lg:py-24 lg_tall:py-24 sm:py-2 text-text-0 font-bold">
      <div class="border-t-4 border-main-0"></div>
      <div class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 sm:hidden">
        <p class="lg:leading-[80px] lg:text-title-size lg_tall:leading-[80px] lg_tall:text-title-size mt-16">리드워크는
          클라이언트에 맞는 테스트 전략 및 프로세스 최적화를 통해 최고의 품질을 제공합니다.</p>
        <p class="lg:text-title-size lg_tall:text-title-size sm:text-[18px] hidden">최적화를 통해 최고의 품질을 제공합니다. </p>
      </div>
      <div class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 lg:hidden lg_tall:hidden">
        <p class="lg:text-title-size lg_tall:text-title-size sm:text-content-size mt-16">리드워크는 클라이언트에 맞는 테스트 전략 및 프로세스
          최적화를 통해 최고의 품질을 제공합니다. </p>
      </div>
      <div class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 lg:mt-20 lg_tall:mt-20 sm:mt-10">
        <div
          class="flex sm:flex-col gap-x-[200px] lg:mt-20 lg_tall:mt-20 sm:mt-10 lg:items-center lg_tall:items-center sm:items-start">
          <p class="text-main-0 font-semibold lg:text-[38px] lg_tall:text-title-test-size sm:text-medium-size">맞춤형
            테스트서비스</p>
          <div class="sm:mt-4">
            <p class="text-text-0 font-semibold lg:text-medium-size lg_tall:text-medium-size sm:text-content-size">리드워크의
              맞춤형 테스트서비스는 10년 이상, 테스트관련 조직세팅, 조직관리,
              <br class="sm:hidden">유사사업 진행노하우를 바탕으로 프로세스관점에서 최적의 테스트서비스를 제안합니다.
            </p>
            <p class="text-text-1 text-content-size sm:text-small-size lg:mt-7 lg_tall:mt-7 sm:mt-5 hidden">컨설팅 서비스는
              리드워크 다양한 검증 업무 경험을 바탕으로
              <br class="sm:hidden lg:hidden lg_tall:hidden">고객사에 효율적인 품질 서비스 제공
            </p>
          </div>
        </div>
        <div
          class="flex sm:flex-col lg_tall:grid lg_tall:grid-cols-2 lg_tall:grid-rows-2 lg:gap-10 lg_tall:gap-4 lg_tall:gap-x-4 sm:gap-4 mt-10 mx-auto">
          <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#fff3c8]" title="진단"
            sub_0="고객사의 이슈 해결을 위한 맞춤형 테스트 서비스는 고객 진단에서 부터 시작합니다." />
          <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#ffcc99]" title="분석"
            sub_0="요구사항 및 개선사항 수립을 통해 잠재된 이슈의 근원을 분석하여 문제점과 해결방안을 제안해 드립니다." />
          <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#ffc2c2]" title="수행"
            sub_0="계획 수립과 수행을 위해 고객사의 의견 수렴과 개선방안에 대한 논의를 지속적으로 진행합니다" />
          <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#c5dddd]" title="결과"
            sub_0="조직의 환경에 최적화된 테스트 서비스를 제안 드립니다." />
        </div>
      </div>
    </div>

    <!-- 테스트 서비스 유형 -->
    <div class="lg:py-24 lg_tall:py-24 sm:py-16 text-text-0 font-bold bg-slate-50">
      <div class="border-t-4 border-main-0"></div>
      <div
        class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 flex sm:flex-col gap-x-[220px] lg:mt-20 lg_tall:mt-20 sm:mt-10 lg:items-center lg_tall:items-center sm:items-start">
        <p class=" text-main-0 font-semibold lg:text-[38px] lg_tall:text-title-test-size sm:text-medium-size">테스트 서비스 유형
        </p>
        <div class="sm:mt-4">
          <p class="text-text-0 font-semibold lg:text-medium-size lg_tall:text-medium-size sm:text-content-size">적합한 테스트
            전략 및 프로세스 최적화를 통한 품질 향상과<br class="sm:hidden">위험 요소를 최소화 할 수 있도록 합니다.</p>
          <p class="text-text-1 text-content-size sm:text-small-size lg:mt-7 lg_tall:mt-7 sm:mt-5">상용 전 또는 상용화 진행 중인
            서비스, 소프트웨어의 품질 검증 및 관리 엔지니어링 전반을 제공합니다.
          </p>
        </div>
      </div>
      <div
        class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 flex sm:flex-col lg:gap-10 lg_tall:gap-10 sm:gap-4 mt-10 justify-center">
        <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#fff3c8]" title="기능 테스트"
          sub_0="QA프로세스 4단계를 걸쳐 수행하여 소프트웨어 제품의 기능을 검증합니다.<br>기능과 관련된 문제를 식별하는데 중점을 두고 정상 작동과 문제점을 확인하기 위한 테스트 기법입니다" />
        <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#ffcc99]" title="호환성 테스트"
          sub_0="고객사 서비스 기능을 확인하여 다양한 운영체제, 모바일 및 웹 종류별 별 그래픽 카드, 디바이스 종류 별 하드웨어 및 소프트웨어 구성에서 호환에 문제가 없는지 확인하는 기법입니다." />
        <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#ffc2c2]" title="사용성 테스트"
          sub_0="유사한 서비스의 경험을 기반으로 예외적인 시나리오를 확인 (통신망,설정 등)하여 사용성을 높이기 위한 테스트 기법입니다" />
      </div>
      <div
        class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 flex sm:flex-col lg:gap-10 lg_tall:gap-10 sm:gap-4 mt-10 justify-center">
        <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#fff3c8]" title="API 테스트"
          sub_0="고객사 서비스의 서버에 프로그램과 프로그램 간의 상호작용을 할 수 있는 통신을 위해 API 테스트하여 개발 효율성을 높이는 테스트입니다." />
        <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#ffcc99]" title="자동화 테스트"
          sub_0="잠재적인 코딩 오류, 병목 현상 및 기타 성능 장애를 식별하기 위해 일련의 단순 반복 테스트를 자동화 소프트웨어 도구를 이용하여 업무리소스를 줄이는 테스트입니다." />
        <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#ffc2c2]" title="성능 테스트"
          sub_0="시스템이 특정 상황에서의 성능(TPS, 응답시간)을 측정하는 테스트로 가용률, 안정성, 확장성을 확인하여 다수의 사용자가 원할한 서비스 사용을 목표로 합니다." />
      </div>
    </div>

    <!-- 화이트박스 test -->
    <div class="lg:px-[10%] lg_tall:px-[10%] sm:px-10 lg:py-24 lg_tall:py-24 sm:py-16 text-text-0 font-bold hidden">
      <img class="rounded-3xl w-full lg:object-fill lg_tall:object-fill lg:h-[600px] lg_tall:h-[600px] sm:object-cover"
        src="https://d3hprqr5b1tsx3.cloudfront.net/img/202401/img_blackbox_011.jpg" alt="">
      <div
        class="flex sm:flex-col gap-x-[240px] lg:mt-20 lg_tall:mt-20 sm:mt-10 lg:items-center lg_tall:items-center sm:items-start">
        <p
          class="lg:basis-[22%] lg_tall:basis-[22%] text-main-0 font-semibold lg:text-title-size lg_tall:text-title-size sm:text-medium-size">
          WhiteBox Test</p>
        <div class="sm:mt-4">
          <p class="text-text-0 font-semibold lg:text-medium-size lg_tall:text-medium-size sm:text-content-size">개발에서
            리소스 낭비를 막기 위해 테스팅 분야의 성능으로<br class="sm:hidden">품질 향상을 할 수 있도록 합니다.</p>
          <p class="text-text-1 text-content-size sm:text-small-size lg:mt-7 lg_tall:mt-7 sm:mt-5">풍부한 경험과 개발 능력을 바탕으로
            모바일 앱/웹, 홈기기, 금융, 자동차, VR/AR, 메타버스, AI 등
            <br class="sm:hidden">다양한 분야의 최적화된 성능 테스트를 제공합니다.
          </p>
        </div>
      </div>
      <div class="flex sm:flex-col lg:gap-10 lg_tall:gap-10 sm:gap-4 mt-10">
        <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#fff3c8]" title="API 테스트"
          sub_0="고객사 서비스의 서버에 프로그램과 프로그램 간의 상호작용을 할 수 있는 통신을 위해 API 테스트하여 개발 효율성을 높이는 테스트입니다." />
        <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#ffcc99]" title="자동화 테스트"
          sub_0="잠재적인 코딩 오류, 병목 현상 및 기타 성능 장애를 식별하기 위해 일련의 단순 반복 테스트를 자동화 소프트웨어 도구를 이용하여 업무리소스를 줄이는 테스트입니다." />
        <ServiceInfo_02 class="basis-full sm:w-[100%]" color="bg-[#ffc2c2]" title="성능 테스트"
          sub_0="시스템이 특정 상황에서의 성능치(TPS, 응답시간)를 측정하는 테스트로 가용률, 안정성, 확장성을 확인하여 다수의 사용자가 원활한 서비스 사용을 목표로 합니다." />
      </div>
    </div>

    <!-- si grow -->
    <div class="relative scrolltrigger hidden">
      <img class="my-24 w-full lg:object-fill lg_tall:object-fill sm:object-cover"
        src="https://d3hprqr5b1tsx3.cloudfront.net/img/202401/si_grow_001.png" alt="">
      <p class="absolute top-[76%] left-[26%] text-white lg:text-6xl lg_tall:text-5xl sm:text-content-size font-bold">{{
        customerCount }}%+</p>
      <p
        class="absolute top-[88%] left-[22%] text-text-0 lg:text-title-size lg_tall:text-medium-size font-bold sm:text-small-size">
        고객사 만족도</p>

      <p class="absolute top-[76%] left-[54%] text-white lg:text-6xl lg_tall:text-5xl font-bold sm:text-content-size">{{
        projectCount }}개</p>
      <p
        class="absolute top-[88%] left-[50%] text-text-0 lg:text-title-size lg_tall:text-medium-size font-bold sm:text-small-size">
        프로젝트 수행 수</p>

      <p class="absolute top-[76%] left-[84%] text-white lg:text-6xl lg_tall:text-5xl font-bold">{{ engineerCount }}명
      </p>
      <p
        class="absolute top-[88%] left-[80%] text-text-0 lg:text-title-size lg_tall:text-medium-size font-bold sm:text-small-size sm:hidden">
        전문 엔지니어</p>
      <p
        class="absolute top-[88%] left-[82%] text-text-0 lg:text-title-size lg_tall:text-medium-size font-bold sm:text-small-size lg:hidden lg_tall:hidden">
        엔지니어</p>

    </div>

    <!-- 차별화 전략 -->
    <div class="lg:py-24 lg_tall:py-24 sm:py-16 bg-slate-50">
      <div class="border-t-4 border-main-0"></div>
      <p class="px-[10%] mt-16 font-bold lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-center">
        리드워크의 차별화 전략</p>
      <p
        class="px-[10%] lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-text-1 text-center mt-10 sm:hidden">
        고객사 프로젝트에 맞는 적절한 인원배치와 교육을 통해<br>품질 검증에 최상의 컨디션을 제공 해드리고 있습니다.</p>
      <p
        class="px-[10%] lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-text-1 text-center mt-10 lg:hidden lg_tall:hidden">
        고객사 프로젝트에 맞는 적절한 인원배치와 교육을 통해 품질 검증에 최상의 컨디션을 제공 해드리고 있습니다.</p>

      <div
        class="px-[10%] lg_tall:grid lg_tall:grid-cols-2 lg_tall:grid-rows-2 lg:flex flex-wrap lg_tall:flex-wrap justify-center mt-10 ">

        <!-- 인원 배치 -->
        <div class="py-5 lg:px-5 lg_tall:px-5 relative basis-[34%] h-[380px] sm:h-[300px] rounded-3xl overflow-hidden"
          @click="isOpen">
          <!-- 이미지 -->
          <img :src="recItems[0].imgSrc" class="w-full h-full rounded-3xl brightness-50" ref="blurDiv">
          <p v-if="!isClicked"
            class="absolute top-[12%] left-20 text-small-size text-main-0 font-semibold title-animation hidden">{{
            recItems[0].engTitle }}</p>
          <p
            class="absolute top-[18%] left-[14%] lg:text-medium-size lg_tall:text-medium-size sm:text-small-size text-white font-semibold title-animation">
            {{
            recItems[0].korTitle }}</p>
          <i v-if="!isClicked" class="fa-solid fa-circle-plus fa-2x absolute top-[34%] left-[14%] text-white"></i>
          <p class="absolute top-[24%] left-[14%] text-white text-[0px] content-animation pr-14 "
            v-dompurify-html="recItems[0].content"></p>
        </div>

        <!-- API 및 교육 진행 -->
        <div class="py-5 lg:px-5 lg_tall:px-5 relative basis-[34%] h-[380px] sm:h-[300px] rounded-3xl overflow-hidden"
          @click="isOpen_1">
          <!-- 이미지 -->
          <img :src="recItems[1].imgSrc" class="w-full h-[100%] rounded-3xl brightness-50" ref="blurDiv_1">
          <p v-if="!isClicked_1"
            class="absolute top-[12%] left-20 text-small-size text-main-0 font-semibold title-animation_1 hidden">{{
            recItems[1].engTitle }}</p>
          <p
            class="absolute top-[18%] left-[14%] lg:text-medium-size lg_tall:text-medium-size sm:text-small-size text-white font-semibold title-animation_1">
            {{
            recItems[1].korTitle }}</p>
          <i v-if="!isClicked_1" class="fa-solid fa-circle-plus fa-2x absolute top-[34%] left-[14%] text-white"></i>
          <p class="absolute top-[24%] left-[14%] text-white text-[0px] content-animation_1 pr-14"
            v-dompurify-html="recItems[1].content"></p>
        </div>

        <!-- 보유 디바이스 -->
        <div class="py-5 lg:px-5 lg_tall:px-5 relative basis-[34%] h-[380px] sm:h-[300px] rounded-3xl overflow-hidden"
          @click="isOpen_2">
          <!-- 이미지 -->
          <img :src="recItems[2].imgSrc" class="w-full h-full rounded-3xl brightness-50" ref="blurDiv_2">
          <p v-if="!isClicked_2"
            class="absolute top-[12%] left-20 text-small-size text-main-0 font-semibold title-animation_2 hidden">{{
            recItems[2].engTitle }}</p>
          <p
            class="absolute top-[18%] left-[14%] lg:text-medium-size lg_tall:text-medium-size sm:text-small-size text-white font-semibold title-animation_2">
            {{
            recItems[2].korTitle }}</p>
          <i v-if="!isClicked_2" class="fa-solid fa-circle-plus fa-2x absolute top-[34%] left-[14%] text-white"></i>
          <p class="absolute top-[24%] left-[14%] text-white text-[0px] content-animation_2 pr-14"
            v-dompurify-html="recItems[2].content"></p>
        </div>

        <!-- 지원 환경 구축 -->
        <div class="py-5 lg:px-5 lg_tall:px-5 relative basis-[34%] h-[380px] sm:h-[300px] rounded-3xl overflow-hidden"
          @click="isOpen_3">
          <!-- 이미지 -->
          <img :src="recItems[3].imgSrc" class="w-full h-full rounded-3xl brightness-50" ref="blurDiv_3">
          <p v-if="!isClicked_3"
            class="absolute top-[12%] left-20 text-small-size text-main-0 font-semibold title-animation_3 hidden">{{
            recItems[3].engTitle }}</p>
          <p
            class="absolute top-[18%] left-[14%] lg:text-medium-size lg_tall:text-medium-size sm:text-small-size text-white font-semibold title-animation_3">
            {{
            recItems[3].korTitle }}</p>
          <i v-if="!isClicked_3" class="fa-solid fa-circle-plus fa-2x absolute top-[34%] left-[14%] text-white"></i>
          <p class="absolute top-[24%] left-[14%] text-white text-[0px] content-animation_3 pr-14"
            v-dompurify-html="recItems[3].content"></p>
        </div>
      </div>
    </div>

    <!-- 계약 형태에 따른 서비스 구성 new-->
    <div class="lg:py-20 lg_tall:py-20 sm:py-12 bg-slate-50 font-basic">
      <div class="border-t-4 border-main-0"></div>
      <p class="px-[10%] mt-16 font-bold lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-center">
        계약 형태에 따른 서비스 구성</p>

      <div class="flex justify-center gap-32 mt-16 flex-wrap">
        <div class="flex flex-col">
          <div
            class="flex flex-col items-center justify-center w-60 h-60 bg-white rounded-full gap-2 border-main-0 border-solid border-2">
            <img src="@/assets/ic_cogwheel.png" alt="Placeholder Image" class="w-[70px] h-[70px]">
            <p class="text-text-0 text-[22px] font-bold">연간운영</p>
            <p class="text-text-1 text-small-size">Annual operation</p>
          </div>
          <div class="w-64 mt-8 flex flex-col text-text-1 ml-10">
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">운영조직 테스트서비스</p>
            </div>
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">운영사이트의 기능개선</p>
            </div>
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">신규기능개발 반영</p>
            </div>
            <div class="flex items-center ">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">OS업그레이드에 따른 반영</p>
            </div>
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">모니터링 등에 대한 서비스</p>
            </div>
           
          </div>        
        </div>

        <div class="flex flex-col">
          <div
            class="flex flex-col items-center justify-center w-60 h-60 bg-white rounded-full gap-2 border-main-0 border-solid border-2">
            <img src="@/assets/ic_test.png" alt="Placeholder Image" class="w-[70px] h-[70px]">
            <p class="text-text-0 text-[22px] font-bold">파일럿</p>
            <p class="text-text-1 text-small-size">Pilot</p>
          </div>
          <div class="w-64 mt-8 flex flex-col text-text-1 ml-10">
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">아웃소싱 도입성과 검증</p>
            </div>
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">소규모 프로젝트 테스트적용</p>
            </div>
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">성과 입증 후 확대 적용</p>
            </div>
          </div>        
        </div>

        <div class="flex flex-col">
          <div
            class="flex flex-col items-center justify-center w-60 h-60 bg-white rounded-full gap-2 border-main-0 border-solid border-2">
            <img src="@/assets/ic_project.png" alt="Placeholder Image" class="w-[70px] h-[70px]">
            <p class="text-text-0 text-[22px] font-bold">프로젝트</p>
            <p class="text-text-1 text-small-size">Project</p>
          </div>
          <div class="w-64 mt-8 flex flex-col text-text-1 ml-10">
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">프로젝트 진행 기간동안 계약</p>
            </div>
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">단기 및 장기 가능</p>
            </div>
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">계획,설계,수행,보고 일정 고려</p>
            </div>
          </div>  
        </div>

        <div class="flex flex-col">
          <div
            class="flex flex-col items-center justify-center w-60 h-60 bg-white rounded-full gap-2 border-main-0 border-solid border-2">
            <img src="@/assets/ic_management.png" alt="Placeholder Image" class="w-[70px] h-[70px]">
            <p class="text-text-0 text-[22px] font-bold">전담관리</p>
            <p class="text-text-1 text-small-size">Exclusive Management</p>
          </div>
          <div class="w-64 mt-8 flex flex-col text-text-1 ml-10">
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">테스트관련업무 전체계약</p>
            </div>
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">주어진 예산내에서 책임수행</p>
            </div>
            <div class="flex items-center">
              <p class="text-[8px]">● &nbsp;&nbsp;</p>
              <p class="">장소부터 모든 유관업무 수행</p>
            </div>
          </div>
        </div>

      </div>

      

    </div>

    <!-- 기대효과 -->
    <div class="lg:py-24 lg_tall:py-24 sm:py-16 bg-white font-basic">
      <div class="border-t-4 border-main-0"></div>
      <p class="px-[10%] mt-16 font-bold lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-center">
        서비스 기대효과</p>

      <div class="flex justify-center gap-32 mt-16 flex-wrap">
        <div class="flex flex-col">
          <div
            class="flex flex-col items-center justify-center w-60 h-60 bg-white rounded-full gap-2 border-main-0 border-solid border-2">
            <img src="@/assets/ic_down_money.png" alt="Placeholder Image" class="w-[70px] h-[70px]">
            <p class="text-text-0 text-[22px] font-bold">비용 절감</p>
            <p class="text-text-1 text-small-size">Lower Costs</p>
          </div>
          <div class="w-64 mt-8 flex flex-col text-text-1">
            <div class="flex items-center">              
              <p class="text-center">관리비용 절감을 통한 저비용,
                고효율의 극대화가 가능합니다.</p>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div
            class="flex flex-col items-center justify-center w-60 h-60 bg-white rounded-full gap-2 border-main-0 border-solid border-2">
            <img src="@/assets/ic_safe.png" alt="Placeholder Image" class="w-[70px] h-[70px]">
            <p class="text-text-0 text-[22px] font-bold">안전한 운영</p>
            <p class="text-text-1 text-small-size">Safe Operation</p>
          </div>
          <div class="w-64 mt-8 flex flex-col text-text-1">
            <div class="flex items-center">              
              <p class="text-center">아웃소싱에 맞는 법령을 준수하여 안전하게 운영 가능합니다.</p>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div
            class="flex flex-col items-center justify-center w-60 h-60 bg-white rounded-full gap-2 border-main-0 border-solid border-2">
            <img src="@/assets/ic_recruitment.png" alt="Placeholder Image" class="w-[70px] h-[70px]">
            <p class="text-text-0 text-[22px] font-bold">고용유연성</p>
            <p class="text-text-1 text-small-size">Flexibility</p>
          </div>
          <div class="w-64 mt-8 flex flex-col text-text-1">
            <div class="flex items-center">              
              <p class="text-center">적재적소에 필요한 인력운영으로 고용유연성을 제고 합니다.</p>
            </div>
          </div>
        </div>

        <div class="flex flex-col">
          <div
            class="flex flex-col items-center justify-center w-60 h-60 bg-white rounded-full gap-2 border-main-0 border-solid border-2">
            <img src="@/assets/ic_focus.png" alt="Placeholder Image" class="w-[70px] h-[70px]">
            <p class="text-text-0 text-[22px] font-bold">전문분야 집중</p>
            <p class="text-text-1 text-small-size">Specialized field</p>
          </div>
          <div class="w-64 mt-8 flex flex-col text-text-1">
            <div class="flex items-center">              
              <p class="text-center">비전문분야를 전문업체에 업무위탁함으로써 기업의 전문분야에 집중 가능합니다.</p>
            </div>
          </div>
        </div>
      </div>    
    </div>

    <!-- 서비스절차 -->
    <div class="lg:py-24 lg_tall:py-24 sm:py-16 text-text-0 font-basic">
      <div class="border-t-4 border-main-0"></div>
      <p class="mt-16 font-bold lg:text-title-size lg_tall:text-title-size sm:text-medium-size text-center">서비스 절차</p>   
      
      <img class="mt-16 mx-auto w-[1400px]" src="@/assets/service.png" alt="">           

    </div>

    <!-- 문의사항 -->
    <Qna class="w-full lg:py-24 lg_tall:py-24 sm:py-16" :qnaData="qnaData" propPlatform="1" />

  </div>

  <Footer />
</template>
<script>
import ServiceInfo_02 from '@/components/baseComponents/ServiceInfo_02.vue'
import Header from '@/components/layoutComponents/Header.vue'
import Footer from '@/components/layoutComponents/Footer.vue'
import Qna from '@/components/baseComponents/Qna.vue'
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
export default {
  components: { ServiceInfo_02, Header, Footer, Qna },

  data() {
    return {
      qnaData: {
        isShowplatform: false,
        smallTitle: '테스트 서비스 문의하기',
        title: 'QA 고민 끝! 리드워크에서 지금 신청 할 수 있습니다.',
        detailList: ['✔️ 평일 2일 내, 답변 드립니다.',
          '✔️ 문의하실 플랫폼을 선택 해 주세요.', '✔️ 필요시 세부정보를 이메일로 보내드립니다. (서비스 제공, 솔루션 문의 등)'
          , '✔️ 전화 상담을 원하신다면 02-2085-0606로 연락 부탁드립니다.']
      },
      customerCount: 0,
      projectCount: 0,
      engineerCount: 0,

      isClicked: false,
      isClicked_1: false,
      isClicked_2: false,
      isClicked_3: false,

      recItems: [
        { id: 1, imgSrc: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202401/si_item_002.JPG', engTitle: 'Staffing and management', korTitle: '이탈 방지 및 대처 방안', content: '-&nbsp;정기적인 인원 면담(주, 월)<br>-&nbsp;현장관리자 운영비 지원<br>-&nbsp;인원 교체 발생시 인수인계 비용 무상 지원<br>-&nbsp;빠른 대체 인원 지원<br>-&nbsp;복지몰 운영 및 회사 이벤트 이탈 방어' },
        { id: 2, imgSrc: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202401/si_item_001.JPG', engTitle: 'API & Training ', korTitle: '엔지니어 등급별 교육', content: '[Test Engineer]<br>&nbsp;-ISTQB 교육 및 자격증 취득<br>&nbsp;- API, ShellScript, SQL, 개발 개념<br><br>[Test Leader]<br>&nbsp;- 리더쉽 교육, 인사 노무 교육<br>&nbsp;-API, ShellScript, SQL, 개발 실무 교육<br>&nbsp;-자동화 구축 실무 교육' },
        { id: 3, imgSrc: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202401/si_item_003.JPG', engTitle: 'Retained Devices', korTitle: '업무 지원', content: '-&nbsp;BTS (Bug Tracking System) 설치<br>-&nbsp;표준화 템플랫 제공<br>-&nbsp;API Tool 설치, 가이드 북 제공<br>-&nbsp;디바이스 지원(1인 1대)<br>-&nbsp;보안 취약점 점검 및 개선' },
        { id: 4, imgSrc: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202401/si_item_005.JPG', engTitle: 'Building a Support Environment', korTitle: '프로젝트에 맞는 인원 배치', content: '-&nbsp;테스트 범위에 따른 투입 인원 선정<br>-&nbsp;해당 프로젝트 필요 등급 배치<br>-&nbsp;유사 프로젝트 경력자 우선 배치<br>-&nbsp;고객 담당자와 원활한 커뮤니케이션' },
      ],
      bosangItems: [
        { imgSrc: 'fa-solid fa-gift', title: '겟아이즈 복지몰 운영', content: '직원 복지를 위한 복지물을 운영하여, 일과 삶의 균형을 잘 유지할 수 있도록 다양한 혜택을 제공합니다.' },
        { imgSrc: 'fa-solid fa-book', title: '도서비, 교육비 지원', content: '직원 복지를 위한 복지물을 운영하여, 일과 삶의 균형을 잘 유지할 수 있도록 다양한 혜택을 제공합니다.' },
        { imgSrc: 'fa-solid fa-gifts', title: '명절 선물 제공', content: '명절마다 특별한 선물을 제공하여, 직원 여러분과 여러분의 가족이 따뜻한 명절을 보낼 수 있도록 합니다.' },
        { imgSrc: 'fa-solid fa-cake-candles', title: '생일축하 겟아이즈 포인트 지급', content: '직원 복지를 위한 복지물을 운영하여, 일과 삶의 균형을 잘 유지할 수 있도록 다양한 혜택을 제공합니다.' },
        { imgSrc: 'fa-solid fa-credit-card', title: '경조비, 경조사 지급', content: '경조사 발생 시 경조비와 경조사 휴가를 제공하여, 중요한 가족 행사에 여유롭게 참여할 수 있도록 지원합니다.' },
        { imgSrc: 'fa-solid fa-laptop', title: '노트북 지급', content: '명절마다 특별한 선물을 제공하여, 직원 여러분과 여러분의 가족이 따뜻한 명절을 보낼 수 있도록 합니다.' },
      ],
    };
  },
  methods: {
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPhone|iPad|iPod|Android/i.test(userAgent);
    },
    isOpen_3() {
      this.isClicked_3 = !this.isClicked_3;
      const blurDiv_3 = this.$refs.blurDiv_3;

      if (this.isClicked_3 == true) {
        blurDiv_3.classList.add('blur-md');

        gsap.fromTo(".title-animation_3",
          { y: 0, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "30px" }, // 시작 값
          {
            y: -30, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "14px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );

        gsap.fromTo(".content-animation_3",
          { y: 100, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "14px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
      else {
        blurDiv_3.classList.remove('blur-md');

        gsap.fromTo(".title-animation_3",
          { y: -30, opacity: 0, fontSize: this.isMobile() == true ? "14px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "30px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
        gsap.fromTo(".content-animation_3",
          { y: 0, opacity: 1, fontSize: this.isMobile() == true ? "14px" : "20px" }, // 시작 값
          {
            y: 100, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 0, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
    },
    isOpen_2() {
      this.isClicked_2 = !this.isClicked_2;
      const blurDiv_2 = this.$refs.blurDiv_2;

      if (this.isClicked_2 == true) {
        blurDiv_2.classList.add('blur-md');

        gsap.fromTo(".title-animation_2",
          { y: 0, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "30px" }, // 시작 값
          {
            y: -30, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "14px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );

        gsap.fromTo(".content-animation_2",
          { y: 100, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "14px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
      else {
        blurDiv_2.classList.remove('blur-md');

        gsap.fromTo(".title-animation_2",
          { y: -30, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "30px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
        gsap.fromTo(".content-animation_2",
          { y: 0, opacity: 1, fontSize: "20px" }, // 시작 값
          {
            y: 100, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 0, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
    },
    isOpen_1() {
      this.isClicked_1 = !this.isClicked_1;
      const blurDiv_1 = this.$refs.blurDiv_1;

      if (this.isClicked_1 == true) {
        blurDiv_1.classList.add('blur-md');

        gsap.fromTo(".title-animation_1",
          { y: 0, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "30px" }, // 시작 값
          {
            y: -30, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "14px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );

        gsap.fromTo(".content-animation_1",
          { y: 100, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "14px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
      else {
        blurDiv_1.classList.remove('blur-md');

        gsap.fromTo(".title-animation_1",
          { y: -30, opacity: 0, fontSize: this.isMobile() == true ? "14px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "30px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
        gsap.fromTo(".content-animation_1",
          { y: 0, opacity: 1, fontSize: this.isMobile() == true ? "14px" : "20px" }, // 시작 값
          {
            y: 100, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 0, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
    },
    isOpen() {
      this.isClicked = !this.isClicked;
      const blurDiv = this.$refs.blurDiv;

      if (this.isClicked == true) {
        blurDiv.classList.add('blur-md');

        gsap.fromTo(".title-animation",
          { y: 0, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "30px" }, // 시작 값
          {
            y: -30, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "14px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );

        gsap.fromTo(".content-animation",
          { y: 100, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "14px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
      else {
        blurDiv.classList.remove('blur-md');

        gsap.fromTo(".title-animation",
          { y: -30, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "30px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
        gsap.fromTo(".content-animation",
          { y: 0, opacity: 1, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 100, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 0, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
    },
  },
  computed: {
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.to(this.$data, {
      customerCount: 97,
      projectCount: 60,
      engineerCount: 50,
      duration: 1,
      scrollTrigger: {
        trigger: ".scrolltrigger",
        start: "top center",
        toggleActions: "restart resume pause",
        onEnter: () => {
          this.customerCount = 0; // 스크롤 트리거가 시작되면 숫자를 0으로 초기화
          this.projectCount = 0;
          this.engineerCount = 0;
        }
      },
      onUpdate: () => {
        this.customerCount = Math.round(this.customerCount);
        this.projectCount = Math.round(this.projectCount);
        this.engineerCount = Math.round(this.engineerCount);
      }
    });
  },
}
</script>

<style>


</style>