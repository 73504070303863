<template>
  <div class="relative flex flex-col justify-start px-8 py-8 rounded-3xl" :class="color">
    <p class="font-semibold lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-center">{{ title }}</p>
    <p class="lg:text-content-size lg_tall:text-content-size sm:text-small-size mt-4 break-words text-text-1"  v-dompurify-html="sub_0" ></p>
  </div>
</template>
<script>

import { gsap } from "gsap"

export default {
  props: ['color', 'title', 'sub_0', 'sub_1'],
  data(){
    return{
      isOpen: false,      
    }
  },
  methods: {
    itemClick(){
      this.isOpen = !this.isOpen;
      const detail = this.$refs.detail;

      console.log(detail);
      console.log(this.$refs.detail);

      if(this.isOpen == true)
      {
        gsap.fromTo(detail,
          { y: 40, opacity: 0 }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)                    
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );        
      }
      else
      {
        return;
      }
    }
  },
}
</script>
