<template>
  <div class="flex flex-col bg-black "
  :class="isMobile == true ? '' : 'h-[100vh]' ">
    <Header />
    <!-- pc버전 -->
    <div
      class="px-[5%] mt-24 h-[90vh] pb-4 grid grid-cols-4 grid-rows-[12] mx-auto gap-6 box-border sm:hidden lg_tall:hidden bg-black">
      <div class="col-start-1 col-end-4 row-start-1 row-end-5 bg-[#6282D6] rounded-[20px] pl-10"
        @mouseover="isHover = true" @mouseleave="isHover = false" @click="goToPage('/company')">
        <div class="flex items-center gap-20 py-10 relative mt-1">
          <p class="font-black font-arial tracking-tighter leading-[0.82]"
            :class="[isHover ? 'text-white' : '', isScreenCheck_1 ? 'text-[130px]' : 'text-[200px]']">
            LEAD
            <br>
            &nbsp;&nbsp;WALK
          </p>
          <div v-if="isLargeScreen" class="mb-40 font-basic text-[24px] absolute left-[62%] lg:top-[74%] md:top-[60%]">
            <p class="font-bold">리드워크는 기술로 새로운 길을 만들어 리드하고</p>
            <p class="font-bold">그 길을 고객과 함께 걸어가고 있습니다.</p>
          </div>
        </div>
        <p class="text-[200px] font-extrabold pl-28 font-arial tracking-tighter hidden">WALK</p>
      </div>
      <div
        class="overflow-hidden col-start-4 col-end-5 row-start-1 row-end-4 bg-[#EDEDED] rounded-[20px] hover:text-main-0 font-basic"
        @click="goToPage('/recruit')">
        <div class="flex-col items-center justify-start pl-[20%] mt-8">
          <p class="text-[22px] font-semibold">채용</p>
          <p class="text-4xl font-bold font-arial tracking-tight mt-8">L-Walk PICK</p>
          <p class="font-semibold mt-5">함께 성장하며 나아갈</p>
          <p class="font-semibold">꿈과 열정있는 리드워커를 모집합니다</p>
        </div>
        <div class="flex flex-col items-end mt-10 mr-10">
          <span class="py-2 px-5 rounded-l-3xl rounded-r-xl bg-black text-white font-semibold">
            더 알아보기
          </span>
        </div>
      </div>
      <div
        class="overflow-hidden col-start-4 col-end-5 row-start-4 row-end-[12] bg-[#A7DDE9] rounded-[20px] px-4 flex flex-col hover:text-main-0 font-basic"
        @click="goToPage('/contactus')">
        <div class="">
          <img class="w-[160px] mt-4 ml-[64%]" src="@/assets/ic_008.png" alt="">
        </div>
        <div class="mx-auto mt-10">
          <p class="text-5xl font-bold font-arial tracking-tight mt-2">Contact us</p>
        </div>
        <div class="pl-5 font-bold mt-28">
          <p class="text-content-size font-black">질문이 있으신가요?</p>
          <p class="text-content-size font-black">친절히 답변 드리겠습니다</p>
        </div>
      </div>
      <div
        class="col-start-1 col-end-2 row-start-5 row-end-[12] pt-4 py-8 pr-8  bg-[#F9E8BD] rounded-[20px] px-5 hover:cursor-pointer hover:text-main-0 font-basic"
        @click="goToPage('/testingservice')">
        <div class="h-[70%] flex flex-col justify-between relative ">
          <div>
            <img class="w-[14%]" src="@/assets/ic_006.png" alt="">
            <p class="font-semibold">Testing</p>
          </div>
          <div>
            <p class="text-lg">서비스 분야</p>
            <p class="font-bold font-arial tracking-tight" :class="[isScreenCheck_1 ? 'text-4xl' : 'text-5xl']">Testing
              Service</p>
          </div>
          <div class="">
            <p class="font-semibold">15년의 협업 경력을 지닌 리드워크의 </p>
            <p class="font-semibold">수준높은 테스트 서비스를 경험해보세요</p>
          </div>
        </div>
        <div class="flex flex-col justify-end items-end h-[30%]">
          <span class="py-2 px-5 rounded-l-3xl rounded-r-xl bg-black text-white font-semibold">
            더 알아보기
          </span>
        </div>
      </div>
      <div
        class="col-start-2 col-end-3 row-start-5 row-end-[12] pt-4 py-8 pr-8  bg-[#9AE1CC] rounded-[20px] px-5 hover:cursor-pointer hover:text-main-0 font-basic"
        @click="goToPage('/geteyes')">
        <div class="h-[70%] flex flex-col justify-between">
          <div>
            <img class="w-[18%]" src="@/assets/ic_007.png" alt="">
          </div>
          <div>
            <p class="text-lg">서비스 분야</p>
            <p class="font-bold leading-10 font-arial tracking-tight"
              :class="[isScreenCheck_1 ? 'text-4xl' : 'text-[44px]']">Geteyes Platform</p>
          </div>
          <div>
            <p class="font-semibold mt-3">현명한 소비 플랫폼 겟아이즈</p>
            <p class="font-semibold">소비자와 판매자 연결의 첫 걸음입니다</p>
          </div>
        </div>
        <div class="flex flex-col justify-end items-end h-[30%]">
          <span class="py-2 px-5 rounded-l-3xl rounded-r-xl bg-black text-white font-semibold">
            더 알아보기
          </span>
        </div>
      </div>
      <div
        class="col-start-3 col-end-4 row-start-5 row-end-[12] pt-4 py-8 pr-8  bg-[#E3C9F6] rounded-[20px] px-5 hover:cursor-pointer hover:text-main-0 font-basic"
        @click="goToPage('/qmeet')">
        <div class="h-[70%] flex flex-col justify-between relative">
          <div>
            <img class="w-[18%]" src="@/assets/ic_qmeet.png" alt="">
            <p class="font-semibold">&nbsp;</p>
          </div>
          <div class="mt-10">
            <p class="text-lg">서비스 분야</p>
            <p class=" font-bold font-arial tracking-tight leading-[50px]"
              :class="[isScreenCheck_1 ? 'text-4xl' : 'text-[44px]']">Q-MEET Platform</p>
          </div>
          <div class="mt-4">
            <p class="font-semibold">COMMING SOON!! 2024년 런칭예정</p>
            <p class="font-semibold">잠시만 기다려주세요</p>
          </div>
        </div>
        <div class="flex flex-col justify-end items-end h-[30%]">
          <span class="py-2 px-5 rounded-l-3xl rounded-r-xl bg-black text-white font-semibold">
            더 알아보기
          </span>
        </div>
      </div>
    </div>

    <!-- 모바일 버전 -->
    <div
      class="lg:hidden lg_tall:hidden px-[5%] h-[90vh] flex flex-col mt-28 pb-7 mx-auto gap-4 box-border font-basic bg-black">
      <!-- LEADWALK -->
      <div class="basis-[30%] relative bg-[#6282D6] rounded-[20px]" @mouseover="isHover = true"
        @mouseleave="isHover = false" @click="goToPage('/company')">
        <div class="cursor-pointer hover:text-white">
          <p class="font-black font-arial text-center text-[12vh] tracking-widest leading-tight">LEAD</p>
          <p class="font-black font-arial text-center text-[12vh] leading-[60px]">WALK</p>
        </div>
      </div>

      <div class="flex basis-[23%] gap-4">
        <!-- Testing Service -->
        <div class="py-4 px-2 bg-[#F9E8BD] rounded-[20px] basis-[33%] relative cursor-pointer"
          @click="goToPage('/testingservice')">
          <img class="w-[30%]" src="@/assets/ic_006.png" alt="">
          <div class="mt-4 hover:text-main-0">
            <p class="font-arial font-semibold mt-2 text-center text-content-size">Testing</p>
            <p class="font-arial font-semibold mt-2 text-center text-content-size leading-3">Service</p>
          </div>
        </div>
        <!-- Geteyes Platform -->
        <div class="py-4 px-2 bg-[#9AE1CC] rounded-[20px] basis-[33%] relative cursor-pointer hover:text-main-0"
          @click="goToPage('/geteyes')">
          <img class="w-[20%]" src="@/assets/ic_009.png" alt="">
          <div class="mt-4">
            <p class="font-arial font-semibold mt-2 text-center text-content-size">Geteyes</p>
            <p class="font-arial font-semibold mt-2 text-center text-content-size leading-3">Platform</p>
          </div>

        </div>
        <!-- Q-MEET Platform -->
        <div class="py-4 px-2 bg-[#E3C9F6] rounded-[20px] basis-[33%] relative cursor-pointer hover:text-main-0"
          @click="goToPage('/qmeet')">
          <img class="w-[40%]" src="@/assets/ic_qmeet.png" alt="">
          <div class="mt-7">
            <p class="font-arial font-semibold mt-2 text-center text-content-size">Q-MEET</p>
            <p class="font-arial font-semibold mt-2 text-center text-content-size leading-3">Platform</p>
          </div>

        </div>
      </div>

      <!-- L-Walk PICK -->
      <div class="bg-[#EDEDED] rounded-[20px]  relative basis-[23%] cursor-pointer hover:text-main-0"
        @click="goToPage('/recruit')">
        <div class="absolute top-1/2 left-1/2 text-center transform -translate-x-1/2 -translate-y-1/2 w-full">
          <p class="text-[40px] font-bold font-arial">L-Walk PICK</p>
          <p class="mt-2">함께 성장하며 나아갈</p>
          <p class="">꿈과 열정있는 리드워커를 모집합니다.</p>
        </div>
      </div>
      <!-- Contact US -->
      <div class="bg-[#A7DDE9] rounded-[20px]  relative basis-[23%] cursor-pointer hover:text-white"
        @click="goToPage('/contactus')">
        <div class="absolute top-1/2 left-1/2 text-center transform -translate-x-1/2 -translate-y-1/2 w-full">
          <p class="text-title-size font-bold font-arial">Contact us</p>
          <p class="mt-2">질문이 있으신가요?</p>
          <p class="">친절히 답변 드리겠습니다</p>
        </div>
      </div>
    </div>

      <!-- pc tall 버전 -->
  <div class="sm:hidden lg:hidden px-[5%] h-[90vh] flex flex-col mt-28 my-7 mx-auto gap-4 box-border font-basic">
    <!-- LEADWALK -->
    <div class="basis-[30%] relative bg-[#6282D6] rounded-[20px]" @mouseover="isHover = true"
      @mouseleave="isHover = false" @click="goToPage('/company')">
      <div class="cursor-pointer hover:text-white">
        <p class="font-black font-arial text-center text-[12vh] tracking-widest leading-tight">LEAD</p>
        <p class="font-black font-arial text-center text-[12vh] leading-[60px] mt-10">WALK</p>
      </div>
    </div>

    <div class="flex basis-[23%] gap-4">
      <!-- Testing Service -->
      <div class="pt-4 px-2 bg-[#F9E8BD] rounded-[20px] basis-[33%]  relative cursor-pointer"
        @click="goToPage('/testingservice')">
        <img class="w-[30%]" src="@/assets/ic_006.png" alt="">
        <div class="mt-4 hover:text-main-0">
          <p class="font-arial font-semibold mt-2 text-center text-[60px]">Testing</p>
          <p class="font-arial font-semibold mt-2 text-center text-[60px] leading-3">Service</p>
        </div>
        <div class="mt-4 absolute bottom-4 right-2">
          <p class="py-2 px-2 rounded-2xl bg-black text-white text-medium-size">
            알아보기
          </p>
        </div>
      </div>
      <!-- Geteyes Platform -->
      <div class="pt-4 px-2 bg-[#9AE1CC] rounded-[20px] basis-[33%] relative cursor-pointer hover:text-main-0"
        @click="goToPage('/geteyes')">
        <img class="w-[20%]" src="@/assets/ic_009.png" alt="">
        <div class="mt-4">
          <p class="font-arial font-semibold mt-2 text-center text-[60px]">Geteyes</p>
          <p class="font-arial font-semibold mt-2 text-center text-[60px] leading-3">Platform</p>
        </div>
        <div class="mt-4 absolute bottom-4 right-2">
          <p class="py-2 px-2 rounded-2xl bg-black text-white text-medium-size">
            알아보기
          </p>
        </div>
      </div>
      <!-- Q-MEET Platform -->
      <div class="pt-4 px-2 bg-[#E3C9F6] rounded-[20px] basis-[33%] relative cursor-pointer hover:text-main-0"
        @click="goToPage('/qmeet')">
        <img class="w-[40%]" src="@/assets/ic_qmeet.png" alt="">
        <div class="mt-7">
          <p class="font-arial font-semibold mt-2 text-center text-[60px]">Q-MEET</p>
          <p class="font-arial font-semibold mt-2 text-center text-[60px] leading-3">Platform</p>
        </div>
        <div class="mt-4 absolute bottom-4 right-2">
          <p class="py-2 px-2 rounded-2xl bg-black text-white text-medium-size">
            알아보기
          </p>
        </div>
      </div>
    </div>

    <!-- L-Walk PICK -->
    <div class="bg-[#EDEDED] rounded-[20px] relative basis-[23%] cursor-pointer hover:text-main-0"
      @click="goToPage('/recruit')">
      <div class="absolute top-1/2 left-1/2 text-center transform -translate-x-1/2 -translate-y-1/2 w-full">
        <p class="text-[70px] font-bold font-arial">L-Walk PICK</p>
        <p class="mt-2 text-[40px]">함께 성장하며 나아갈</p>
        <p class="text-[40px]">꿈과 열정있는 리드워커를 모집합니다.</p>
        <!-- <div class="mt-4 absolute bottom-4 right-2">
          <p class="py-2 px-2 rounded-2xl bg-black text-white text-medium-size">
            알아보기
          </p>
        </div> -->
      </div>
    </div>
    <!-- Contact US -->
    <div class="bg-[#A7DDE9] rounded-[20px] relative basis-[23%] cursor-pointer hover:text-white"
      @click="goToPage('/contactus')">
      <div class="absolute top-1/2 left-1/2 text-center transform -translate-x-1/2 -translate-y-1/2 w-full">
        <p class="text-[70px] font-bold font-arial">Contact us</p>
        <p class="mt-2 text-[40px]">질문이 있으신가요?</p>
        <p class="text-[40px]">친절히 답변 드리겠습니다</p>
        <!-- <div class="mt-4 absolute bottom-4 right-2">
          <p class="py-2 px-2 rounded-2xl bg-black text-white text-medium-size">
            알아보기
          </p>
        </div> -->
      </div>
    </div>
  </div>
  </div>







  <Footer class="hidden" />
</template>
<script>

import Header from '@/components/layoutComponents/Header.vue'
import Footer from '@/components/layoutComponents/Footer.vue'

export default {
  components: {
    Header, Footer
  },
  data() {
    return {
      isHover: false,
      windowWidth: window.innerWidth,
    }
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  computed: {
    isLargeScreen() {
      return this.windowWidth >= 1800;
    },
    isScreenCheck_1() {
      return this.windowWidth < 1200;
    },
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPhone|iPad|iPod|Android/i.test(userAgent);
    }
  },
  methods: {
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    goToPage(path) {
      this.$router.push(path)
    }
  },
}
</script>