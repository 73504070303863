<template>
  <div class="lg:flex lg_tall:flex px-[10%] sm:flex-col items-start justify-center gap-40 font-basic">
    <!-- Left Side Content -->
    <div class="">
      <p class="text-main-0 font-semibold text-small-size lg:text-title-size lg_tall:text-title-size sm:text-medium-size">{{ qnaData.smallTitle }}</p>
      <h2 class="font-bold lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-text-0 mb-6" v-dompurify-html="qnaData.title"></h2>
      <p class="text-gray-600 mb-4 lg:text-content-size lg_tall:text-content-size sm:text-[14px]">사업에 유용한 정보와 컨설팅을 제공해 드립니다.</p>
      <ul class="text-text-1 text-content-size">
        <li v-for="(detail, index) in qnaData.detailList" :key="index">
          <p class="text-text-1 lg:text-content-size lg_tall:text-content-size sm:text-small-size">{{ detail }}</p>
        </li>
      </ul>
    </div>

    <!-- Right Side Form -->
    <div class="sm:mt-10">
      <div class="max-w-md mx-auto">
        <!-- Form starts -->
        <form @submit.prevent="submitInquiry"
          action="https://script.google.com/macros/s/AKfycbxkSLWI8SErbff6Pw5IQWLlnTyHhM4MyWhwGomlUA7TJ-VdJdVD7Zpzd3v_5Du538BE/exec">
          <div class="flex gap-5">
            <div class="flex flex-col mb-4">
              <div class="flex">
                <label for="companyName" class="mb-2 text-small-size text-text-1">회사 이름</label>
                <span class="text-[#FF5252]">&nbsp;*</span>
              </div>
              <input type="text" id="companyName" v-model="form.companyName"
                class="placeholder-ellipsis shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="회사명을 입력하세요">
            </div>
            <div class="flex flex-col mb-4">
              <div class="flex">
                <label for="companyName" class="mb-2 lg:text-small-size lg_tall:text-small-size sm:text-[15px] text-text-1">이름</label>
                <span class="text-[#FF5252]">&nbsp;*</span>
              </div>
              <input type="text" id="name" v-model="form.name"
                class="placeholder-ellipsis shadow appearance-none border rounded w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="이름을 입력하세요.">
            </div>
          </div>
          <div class="flex gap-5 mt-5">
            <div class="flex flex-col mb-4">
              <div class="flex">
                <label for="companyName" class="mb-2 text-small-size text-text-1">이메일</label>
                <span class="text-[#FF5252]">&nbsp;*</span>
              </div>
              <input type="email" id="email" v-model="form.email"
                class="placeholder-ellipsis shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="이메일주소를 입력하세요">
            </div>
            <div class="flex flex-col mb-4">
              <div class="flex">
                <label for="companyName" class="mb-2 text-small-size text-text-1">휴대전화</label>
                <span class="text-[#FF5252]">&nbsp;*</span>
              </div>
              <input type="tel" id="phone" v-model="form.phone"
                class="placeholder-ellipsis shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                placeholder="휴대전화를 입력하세요.">
            </div>
          </div>
          <div v-if="qnaData.isShowplatform" class="flex flex-col mb-4 mt-5">
            <div class="flex">
              <label for="platform" class="mb-2 text-small-size">문의 플랫폼</label>
              <span class="text-[#FF5252]">&nbsp;*</span>
            </div>
            <select id="platform" v-model="form.platform"
              class="shadow border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline">
              <option value="" disabled selected>선택해주세요</option>
              <option value="Testing Service">Testing Service</option>
              <option value="Geteyes">Geteyes</option>              
            </select>
          </div>
          <div class="flex flex-col mb-4 mt-5">
            <div class="flex">
              <label for="companyName" class="mb-2 text-small-size text-text-1">문의 사항</label>
              <span class="text-[#FF5252]">&nbsp;*</span>
            </div>
            <textarea id="question" v-model="form.question"
              class="h-28 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="문의사항을 입력하세요">
            </textarea>
          </div>
          <div class="flex flex-col mb-6">
            <label class="inline-flex items-center">
              <input type="checkbox" v-model="form.agreement" class="form-checkbox text-main-0" >
              <span class="ml-2 text-gray-700 text-sm">개인정보처리방침에 동의합니다.</span>
              <span class="text-[#FF5252]">&nbsp;*</span>
            </label>
          </div>
          <!-- 로딩 인디케이터 -->
          <button 
            class="w-full text-content-size bg-main-0 hover:bg-emerald-600 text-white font-bold py-2 rounded focus:outline-none focus:shadow-outline"
            >
            문의하기
            <i :class="[isLoading ? 'animate-spin fa-solid fa-spinner' : '']"></i>
          </button>
        </form>
        <!-- Form ends -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['qnaData', 'propPlatform'],

  methods: {
    toggleSelection(item) {
      this.selectedItem = this.selectedItem === item ? null : item;
    },    
    submitInquiry() {
      
      if (!this.isFormValid) {
        console.log("submitInquiry 1");
        alert('필수 항목을 모두 입력해주세요.');
        return;
      }
      
      this.isLoading = true; // 로딩 시작
      // 폼 데이터를 URL 인코딩된 형태로 준비
      if(this.propPlatform =='1')
      {
        this.form.platform = "Testing Service";
      }
      else if(this.propPlatform =='2')
      {
        this.form.platform = "Geteyes";
      }
      

      const formData = new URLSearchParams();
      Object.keys(this.form).forEach(key => {
        formData.append(key, this.form[key]);
      });

      // Google Apps Script URL
      const scriptURL = 'https://script.google.com/macros/s/AKfycbx_LvEF3n3reUw02TWIESjYXDehpnRNGwUivS0W0DyLI_KWOYsFd0F3Lci7EK98yO9M/exec';

      // 폼 데이터 전송
      fetch(scriptURL, {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
      })
        .then(response => {
          console.log('Success!', response);
          this.formSubmitted = true;
          alert('빠른시일 내에 답변 드리도록 하겠습니다. 감사합니다.');
          this.resetForm();
        })
        .catch(error => {
          console.error('Error!', error.message);
        })
        .finally(() => {
          this.isLoading = false; // 로딩 종료
        });
    },
    resetForm() {
      this.form = {
        companyName: '',
        companyNumber: '',
        email: '',
        phone: '',
        platform: '',
        question: '',
        agreement: false,
        name: ''
      };
    }

  },

  computed: {
    filteredList() {
      return this.questions.filter((q) => q.category === this.currentTab);
    },
    isFormValid() {          

      if(!this.propPlatform)
        return this.form.companyName && this.form.email && this.form.phone && this.form.platform && this.form.question && this.form.agreement;
      else
        return this.form.companyName && this.form.email && this.form.phone && this.form.question && this.form.agreement;
    }
  },

  data() {
    return {
      isLoading: false, // 로딩 상태 추가
      form: {
        companyName: '',
        companyNumber: '',
        name: '',
        email: '',
        platform: '',
        phone: '',
        question: '',
        agreement: false,
      },
      formSubmitted: false,
    }
  }
}
</script>

<style scoped>
.placeholder-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>