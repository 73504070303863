<template>
  <Header />
  <div class="w-full h-[100vh] font-basic text-text-0 mt-28">
    <div class="flex h-[100%] flex-col items-center justify-center bg-slate-50">
      <p class="font-bold lg:text-[80px] lg_tall:text-[80px] sm:text-title-size text-center">Comming soon</p>
      <p class="font-bold lg:text-[60px] lg_tall:text-[60px] sm:text-[28px]"><span class="text-main-0">큐밋</span>은 현재 오픈 준비중입니다.</p>
    </div>    
  </div>
  <Footer class="end-0"/>
</template>
<script>
import Header from '@/components/layoutComponents/Header.vue'
import Footer from '@/components/layoutComponents/Footer.vue'
export default {
  components: {
    Header, Footer
  },  
}
</script>
