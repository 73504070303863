import { createApp } from 'vue'
import App from './App.vue'
import "./index.css";
import router from "./routes/index.js";
import VueDOMPurifyHTML from 'vue-dompurify-html'
import { createGtm } from '@gtm-support/vue-gtm'

// Vue 애플리케이션 생성
const app = createApp(App)

// Google Tag Manager 설정 추가
app.use(
  createGtm({
    id: "GTM-TR4XGN32", // Google Tag Manager ID
    vueRouter: router   // 라우터와 연동하여 페이지 전환 시에도 GTM이 작동하도록 설정
  })
)

// 기존 사용하던 플러그인과 라우터 추가
app.use(router)
app.use(VueDOMPurifyHTML)

// 애플리케이션 마운트
app.mount('#app')