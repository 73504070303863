<template>
  <nav
    class="flex justify-between lg:px-20 lg_tall:px-20 items-center lg:h-[80px] lg_tall:h-[80px] w-full font-basic fixed top-0 z-20"
    :class="currentPageName == true ? 'bg-black' : 'bg-white' ">

    <div class="lg:ml-[-10px] lg_tall:ml-[-10px] flex-shrink-0 mt-2">
      <img class="cursor-pointer w-[80%] sm:w-[60%]" 
      :src="currentPageName == true ? require('@/assets/ic_leadwalk_logl - white.png') : require('@/assets/ic_leadwalk_logl.png') "  alt="" @click="goToPage('/')">
    </div>
    <ul class="flex  font-semibold text-[26px] sm:hidden lg_tall:hidden">
      <li class="pr-[110px] shrink cursor-pointer hover:text-main-0" :class="currentPageName == true ? 'text-white' : 'text-black' "  
      @click="goToPage('/company')">Company
      </li>

      <!-- BUSINESS 항목과 드롭다운 메뉴를 감싸는 컨테이너 -->
      <li class="relative " @mouseover="dropdownOpen = true" @mouseleave="dropdownOpen = false">
        <button class="pr-[130px] shrink cursor-pointer hover:text-main-0"
        :class="currentPageName == true ? 'text-white' : 'text-black' ">Business</button>
        <ul v-if="dropdownOpen" class="absolute left-0 mt-0 py-2 shadow-xl bg-white" @mouseover="dropdownOpen = true"
          @mouseleave="dropdownOpen = false">
          <li class="px-4 py-2 cursor-pointer" @click="goToPage('/testingservice')">
            <p class="hover:text-main-0 hover:font-bold font-normal text-content-size text-text-1">Testing</p>
          </li>
          <li class="px-4 py-2 cursor-pointer" @click="goToPage('/geteyes')">
            <p class="hover:text-main-0 hover:font-bold font-normal text-content-size text-text-1">Geteyes</p>
          </li>
          <li class="px-4 py-2 cursor-pointer" @click="goToPage('/qmeet')">
            <p class="hover:text-main-0 hover:font-bold font-normal text-content-size text-text-1">Q-MEET</p>
          </li>
        </ul>
      </li>

      <li class="pr-[130px] shrink cursor-pointer hover:text-main-0"
      :class="currentPageName == true ? 'text-white' : 'text-black' " @click="goToPage('/recruit')">Recruit</li>
      <li class="pr-[130px] cursor-pointer hover:text-main-0"
      :class="currentPageName == true ? 'text-white' : 'text-black' " @click="goToPage('/contactus')">Contact us</li>
    </ul>

    <i ref="menubarIcon" class="fa-solid fa-bars fa-2x lg:hidden mr-10 cursor-pointer"
    :class="currentPageName == true ? 'text-white' : 'text-black' "
      @click="mdMenuBarClick"></i>
  </nav>

  <!-- 모바일 뷰일 경우 메뉴 바 -->
  <div v-if="isMdMenuOpen" class="font-basic"
  :class="currentPageName == true ? 'bg-black' : 'bg-white' ">
    <div class="flex-col fixed left-0  w-full px-8 h-full text-text-0 z-10"
      :class="[isMobile ? 'sm:top-[8%] lg_tall:top-[4%] lg:top-[4%]' : 'sm:top-[6%] lg_tall:top-[4%] lg:top-[4%]', currentPageName == true ? 'bg-black' : 'bg-white']">
      <div class="flex justify-between items-center py-5 cursor-pointer hover:text-main-0" @click="goToPage('/company')">
        <p class="lg:text-medium-size lg_tall:text-content-size sm:text-content-size font-semibold"
        :class="currentPageName == true ? 'text-white' : 'text-black' ">Company</p>
      </div>
      <div class="flex justify-between items-center py-5 cursor-pointer hover:text-main-0"
        @click="toggleBusinessDropdown">
        <p class="lg:text-medium-size lg_tall:text-content-size sm:text-content-size font-semibold"
        :class="currentPageName == true ? 'text-white' : 'text-black' ">Business</p>
        <i :class="!isBusinessDropdownOpen ? 'fa-solid fa-plus fa-2x' : 'fa-solid fa-minus fa-2x'"></i>
      </div>

      <!-- 드롭다운 메뉴 -->
      <div v-if="isBusinessDropdownOpen" class="pl-4 text-text-1 lg:text-medium-size sm:text-content-size">
        <div class="py-2 cursor-pointer hover:text-main-0" @click="goToPage('/testingservice')"
        :class="currentPageName == true ? 'text-[#EDEDED]' : 'text-black' ">Testing</div>
        <div class="py-2 cursor-pointer hover:text-main-0"
        :class="currentPageName == true ? 'text-[#EDEDED]' : 'text-black' " @click="goToPage('/geteyes')">Geteyes</div>
        <div class="py-2 cursor-pointer hover:text-main-0"
        :class="currentPageName == true ? 'text-[#EDEDED]' : 'text-black' " @click="goToPage('/qmeet')">Q-Meet</div>
      </div>

      <div class="flex justify-between items-center py-5 cursor-pointer hover:text-main-0"
      :class="currentPageName == true ? 'text-white' : 'text-black' " @click="goToPage('/recruit')">
        <p class="tlg:text-medium-size lg_tall:text-content-size sm:text-content-size font-semibold">Recruit</p>
      </div>
      <div class="flex justify-between items-center py-5 cursor-pointer hover:text-main-0"
      :class="currentPageName == true ? 'text-white' : 'text-black' "
        @click="goToPage('/contactus')">
        <p class="lg:text-medium-size lg_tall:text-content-size sm:text-content-size font-semibold">Contact us</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dropdownOpen: false,
      isMdMenuOpen: false,
      isBusinessDropdownOpen: false,
    }
  },
  computed: {
    currentPageName() {      
      if(this.$route.path == '/')
    {
      return true;
    }
    else
    {
      return false;
    }    
  },
    isMdMenuOpend() {
      return this.isMdMenuOpen;
    },
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPhone|iPad|iPod|Android/i.test(userAgent);
    },
  },
  methods: {
    goToPage(path) {
      if (this.$route.path != path) {
        this.isMdMenuOpen = false;
        this.$router.push(path);
      }
      else {
        this.isMdMenuOpen = true;
        this.mdMenuBarClick();
      }
    },
    toggleBusinessDropdown() {
      this.isBusinessDropdownOpen = !this.isBusinessDropdownOpen;
    },
    mdMenuBarClick() {
      this.isMdMenuOpen = !this.isMdMenuOpen;
      const menubarIcon = this.$refs.menubarIcon;

      if (!this.isMdMenuOpen) {
        menubarIcon.classList.remove("fa-xmark");
        menubarIcon.classList.add("fa-bars");
      }
      else {
        menubarIcon.classList.add("fa-xmark");
        menubarIcon.classList.remove("fa-bars");
      }
    }
  },
}
</script>
