<template>
  <Header />
  <div class="w-full font-basic text-text-0 box-border lg:pt-24 lg_tall:pt-24 sm:pt-10">
    <div class="lg:py-20 lg_tall:py-20 sm:py-20 px-[10%]">
      <img class="sm:object-cover rounded-3xl lg:w-[1920px] lg:h-[500px] " src="https://d3hprqr5b1tsx3.cloudfront.net/img/202401/img_recruit_011.jpg" alt="">
      <p class="lg:mt-20 lg_tall:mt-20 sm:mt-10 font-semibold lg:text-medium-size lg_tall:text-medium-size sm:text-[28px]">What we're looking for</p>
      <p class="text-content-size mt-8">리드워크는 정보의 패러다임을 바꿀 서비스를 만들어 팀원들과 함께 발맞춰가고 있습니다. </p>
      <p class="text-content-size">기술력으로 파트너와 고객 그리고 리드워커 모두가 끊임없이 리드할 수 있도록 멋진 기회를 선사할 예정입니다.</p>
      <p class="text-content-size mt-8">팀워크와 효율을 극대화할 줄 알고, 추진력을 탑재한 인재라고 생각하시나요?</p>
      <p class="text-medium-size mt-8">지금, 이 여정을 함께 즐길 수 있는 팀원의 ⛴️탑승을 기대합니다!</p>
    </div>

    <!-- 리드워크 4가지 기조 -->
    <div class="lg:py-24 lg_tall:py-24 sm:py-16 bg-slate-50 px-[10%]">
      <div class="flex font-bold lg:text-title-size lg_tall:text-[46px] sm:text-[20px] justify-center">       
        <div class="">
          <p>리드워커는 <span class="text-main-0">4가지를 </span>중심으로 함께 걸어갑니다.</p>
        </div>
      </div>      
      <div class="lg_tall:grid lg_tall:grid-cols-2 lg_tall:grid-rows-2 lg:flex gap-4 flex-wrap justify-center mt-10">
        <!-- 인성 -->
        <div class="py-5 lg:px-5 lg_tall:px-5 relative basis-[34%] h-[400px] sm:h-[320px] rounded-3xl overflow-hidden" @click="isOpen">
          <!-- 이미지 -->
          <img :src="recItems[0].imgSrc" class="w-full h-full rounded-3xl brightness-[.8]" ref="blurDiv">
          <p v-if="!isClicked"
            class="absolute top-[12%] left-[14%] text-small-size text-main-0 font-semibold title-animation">{{
              recItems[0].engTitle }}</p>
          <p class="absolute top-[18%] left-[14%] lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-white font-semibold title-animation">{{
            recItems[0].korTitle }}</p>
            <i v-if="!isClicked" class="fa-solid fa-circle-plus fa-2x absolute top-[34%] left-[14%] text-white"></i>        
          <p class="absolute top-[28%] left-[14%] text-white text-[0px] content-animation pr-14">{{ recItems[0].content }}
          </p>          
        </div>

        <!-- 사용자관점 -->
        <div class="py-5 lg:px-5 lg_tall:px-5 relative basis-[34%] h-[400px] sm:h-[320px] rounded-3xl overflow-hidden" @click="isOpen_1">
          <!-- 이미지 -->
          <img :src="recItems[1].imgSrc" class="w-full h-full rounded-3xl brightness-[.8]" ref="blurDiv_1">
          <p v-if="!isClicked_1"
            class="absolute top-[12%] left-[14%] text-small-size text-main-0 font-semibold title-animation_1">{{
              recItems[1].engTitle }}</p>
          <p class="absolute top-[18%] left-[14%] lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-white font-semibold title-animation_1">{{
            recItems[1].korTitle }}</p>
          <i v-if="!isClicked_1" class="fa-solid fa-circle-plus fa-2x absolute top-[34%] left-[14%] text-white"></i>        
          <p class="absolute top-[28%] left-[14%] text-white text-[0px] content-animation_1 pr-14">{{ recItems[1].content }}
          </p>          
        </div>

        <!-- 하나목표 -->
        <div class="py-5 lg:px-5 lg_tall:px-5 relative basis-[34%] h-[400px] sm:h-[320px] rounded-3xl overflow-hidden" @click="isOpen_2">
          <!-- 이미지 -->
          <img :src="recItems[2].imgSrc" class="w-full h-full rounded-3xl brightness-[.8]" ref="blurDiv_2">
          <p v-if="!isClicked_2"
            class="absolute top-[12%] left-[14%] text-small-size text-main-0 font-semibold title-animation_2">{{
              recItems[2].engTitle }}</p>
          <p class="absolute top-[18%] left-[14%] lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-white font-semibold title-animation_2">{{
            recItems[2].korTitle }}</p>
          <i v-if="!isClicked_2" class="fa-solid fa-circle-plus fa-2x absolute top-[34%] left-[14%] text-white"></i>        
          <p class="absolute top-[28%] left-[14%] text-white text-[0px] content-animation_2 pr-14">{{ recItems[2].content }}
          </p>          
        </div>

        <!-- 어제보다 -->
        <div class="py-5 lg:px-5 lg_tall:px-5 relative basis-[34%] h-[400px] sm:h-[320px] rounded-3xl overflow-hidden" @click="isOpen_3">
          <!-- 이미지 -->
          <img :src="recItems[3].imgSrc" class="w-full h-full rounded-3xl brightness-[.6]" ref="blurDiv_3">
          <p v-if="!isClicked_3"
            class="absolute top-[12%] left-[14%] text-small-size text-main-0 font-semibold title-animation_3">{{
              recItems[3].engTitle }}</p>
          <p class="absolute top-[18%] left-[14%] lg:text-medium-size lg_tall:text-medium-size sm:text-content-size text-white font-semibold title-animation_3">{{
            recItems[3].korTitle }}</p>
          <i v-if="!isClicked_3" class="fa-solid fa-circle-plus fa-2x absolute top-[34%] left-[14%] text-white"></i>        
          <p class="absolute top-[28%] left-[14%] text-white text-[0px] content-animation_3 pr-14">{{ recItems[3].content }}
          </p>          
        </div>

      </div>
    </div>

    <!-- 보상과 지원 -->
    <div class="lg:py-24 lg_tall:py-24 sm:py-16 px-[10%]">
      <p class="lg:text-title-size lg_tall:text-title-size sm:hidden font-bold">열심히 일하는 당신을 위해 보상과 지원을 아끼지 않습니다.</p>
      <p class="lg:hidden lg_tall:hidden sm:text-[20px] font-bold">열심히 일하는 당신을 위해<br>보상과 지원을 아끼지 않습니다.</p>
      <div class="lg:grid lg_tall:grid lg:grid-rows-2 lg:grid-cols-3 lg:gap-5 lg_tall:grid-rows-2 lg_tall:grid-cols-3 lg_tall:gap-5 mt-10 sm:flex-col">
        <RecBosang v-for="bosangItem in bosangItems" :imgSrc="bosangItem.imgSrc" :content="bosangItem.content"
          :title="bosangItem.title" :key="bosangItem" />
      </div>
    </div>

    <!-- 함꼐할 동료 찾기 -->
    <div class="py-24 bg-slate-50 px-[10%]">
      <p class="lg:text-title-size lg_tall:text-title-size sm:text-[26px] font-bold">리드워크와 함께할 동료를 찾습니다.</p>

      <p class="text-content-size mt-10">함께 성장하고, 함께 성취해나갈 준비가 되어있는 분들의 지원을 기다립니다.</p>
      <p class="text-content-size">리드워크와 함께 미래를 향한 여정에 동참하세요!</p>

      <div class="mt-10 lg:flex sm:flex-col lg:gap-10 lg_tall:gap-6 flex justify-center">
        <a class="px-8 py-2 border border-text-3 flex justify-around hover:cursor-pointer items-center"
          target="_blank" href="https://www.jobkorea.co.kr/Recruit/Co_Read/Recruit/C/lwalk20?ChkDispType=1">
          <p class="text-text-0 text-content-size">잡코리아로 지원하기</p>
          <i class="fa-solid fa-person-walking-luggage fa-2x ml-5"></i>
        </a>

        <a class="sm:mt-5 px-8 py-2 border border-text-3 flex justify-around hover:cursor-pointer items-center"
          target="_blank" href="https://www.saramin.co.kr/zf_user/company-info/view?csn=Z0lJdUcrNUkrWXA5RzNCbVJ4eW1Xdz09">
          <p class="text-text-0 text-content-size">사람인으로 지원하기</p>
          <i class="fa-solid fa-person-walking-luggage fa-2x ml-5"></i>
        </a>

        <a class="sm:mt-5 px-8 py-2 border border-text-3 flex justify-around hover:cursor-pointer items-center"
          href="mailto:work_life@l-walk.com">
          <p class="text-text-0 text-content-size">메일로 지원하기</p>
          <i class="fa-solid fa-envelope fa-2x ml-5"></i>
        </a>

      </div>

    </div>

  </div>
  <Footer />
</template>
<script>
import Header from '@/components/layoutComponents/Header.vue'
import Footer from '@/components/layoutComponents/Footer.vue'
import RecBosang from '@/components/baseComponents/RecBosang.vue'

import { gsap } from "gsap"
export default {
  components: { Header, Footer, RecBosang },
  data() {
    return {
      isClicked: false,
      isClicked_1: false,
      isClicked_2: false,
      isClicked_3: false,

      recItems: [
        { id: 1, imgSrc: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202312/img_recruit_005.jpg', engTitle: 'Personality & attitude matter', korTitle: '인성이 시작이자 끝', content: '우리는 각 개인의 인성이 팀 전체와 그 너머에 긍정적인 영향을 미칠 수 있다고 생각합니다. 이렇게 한 사람의 좋은 인성은 연쇄 반응을 일으켜 많은 사람들에게 긍정적인 변화를 가져오고, 결국 그 팀 분위기를 밝고 긍정적으로 만들 수 있습니다.' },
        { id: 2, imgSrc: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202312/img_recruit_006.jpg', engTitle: 'User-oriented', korTitle: '철저한 사용자 관점', content: '우리 가족이나 지인이 이 서비스를 사용한다면 어떨까? 라는 생각으로 서비스를 만들고 있습니다. 이러한 마음가짐은 우리가 제공하는 모든 것에 대한 기준을 높이고, 신뢰성과 만족도를 지속적으로 개선하려는 우리의 의지를 반영합니다.' },
        { id: 3, imgSrc: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202312/img_recruit_004.jpg', engTitle: 'One team, One Goal!', korTitle: '하나의 목표를 향해 한 팀처럼!', content: '각자가 자신의 역할에 최선을 다하는 동시에, 서로를 지원하고 협력하여 공통된 목표를 향해 나아갑니다. 이러한 팀워크는 고객에게 긍정적이고 지속적인 영향을 미치는 작용을 합니다.' },
        { id: 4, imgSrc: 'https://d3hprqr5b1tsx3.cloudfront.net/img/202312/img_recruit_003.jpg', engTitle: 'Today is not yesterday', korTitle: '어제보다 발전한 오늘', content: '오늘이 전날보다 더 나은 자신을 되길위해 노력합니다. 우리는 모든 팀원이 지속적으로 배우고, 성장하며, 발전할 수 있는 환경을 제공함으로써 이 목표를 실현하고자 합니다.' },
      ],
      bosangItems: [
        { imgSrc: 'fa-solid fa-gift', title: '복지몰 운영', content: '직원 복지를 위한 복지물을 운영하여, 일과 삶의 균형을 잘 유지할 수 있도록 다양한 혜택을 제공합니다.' },
        { imgSrc: 'fa-solid fa-book', title: '도서비, 교육비 지원', content: '직원들의 자기개발을 할 수 있게 지원하고 지속적인 학습과 발전을 위한 환경을 제공하기 위해 최선을 다하고 있습니다.' },
        { imgSrc: 'fa-solid fa-gifts', title: '명절 선물 제공', content: '명절마다 특별한 선물을 제공하여, 직원 여러분과 여러분의 가족이 따뜻한 명절을 보낼 수 있도록 합니다.' },
        { imgSrc: 'fa-solid fa-cake-candles', title: '생일 포인트 지급', content: '생일을 축하하기 위해, 생일을 맞은 직원들에게 겟아이즈 포인트를 지급하고 있으며 다양한 상품을 구매할 수 있습니다.' },
        { imgSrc: 'fa-solid fa-credit-card', title: '경조비, 경조사 지급', content: '경조사 발생 시 경조비와 경조사 휴가를 제공하여, 중요한 가족 행사에 여유롭게 참여할 수 있도록 지원합니다.' },
        { imgSrc: 'fa-solid fa-laptop', title: '노트북 지급', content: '직원들이 최상의 환경에서 일할 수 있도록 노트북을 지급하여  업무 효율성을 극대화하고 만족도를 높이고 있습니다.' },
      ],
    }
  },
  methods: {
    isMobile() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      return /iPhone|iPad|iPod|Android/i.test(userAgent);
    },
    isOpen_3() {
      this.isClicked_3 = !this.isClicked_3;
      const blurDiv_3 = this.$refs.blurDiv_3;

      if (this.isClicked_3 == true) {
        blurDiv_3.classList.add('blur-md');

        gsap.fromTo(".title-animation_3",
          { y: 0, opacity: 0, fontSize: this.isMobile() == true ? "20px" : "30px"}, // 시작 값
          {
            y: -30, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );

        gsap.fromTo(".content-animation_3",
          { y: 100, opacity: 0, fontSize: this.isMobile() == true ? "20px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
      else {
        blurDiv_3.classList.remove('blur-md');

        gsap.fromTo(".title-animation_3",
          { y: -30, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "20px" : "30px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
        gsap.fromTo(".content-animation_3",
          { y: 0, opacity: 1, fontSize: "20px" }, // 시작 값
          {
            y: 100, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "20px" : "20px",
            opacity: 0, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
    },
    isOpen_2() {
      this.isClicked_2 = !this.isClicked_2;
      const blurDiv_2 = this.$refs.blurDiv_2;

      if (this.isClicked_2 == true) {
        blurDiv_2.classList.add('blur-md');

        gsap.fromTo(".title-animation_2",
          { y: 0, opacity: 0, fontSize: this.isMobile() == true ? "20px" : "30px" }, // 시작 값
          {
            y: -30, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );

        gsap.fromTo(".content-animation_2",
          { y: 100, opacity: 0, fontSize: this.isMobile() == true ? "20px" : "20px"  }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
      else {
        blurDiv_2.classList.remove('blur-md');

        gsap.fromTo(".title-animation_2",
          { y: -30, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "20px" : "30px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
        gsap.fromTo(".content-animation_2",
          { y: 0, opacity: 1, fontSize: "20px" }, // 시작 값
          {
            y: 100, // 종료 위치 (y축으로 100px 위로)        
            fontSize: "20px",
            opacity: 0, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
    },
    isOpen_1() {
      this.isClicked_1 = !this.isClicked_1;
      const blurDiv_1 = this.$refs.blurDiv_1;

      if (this.isClicked_1 == true) {
        blurDiv_1.classList.add('blur-md');

        gsap.fromTo(".title-animation_1",
          { y: 0, opacity: 0, fontSize: this.isMobile() == true ? "20px" : "30px" }, // 시작 값
          {
            y: -30, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );

        gsap.fromTo(".content-animation_1",
          { y: 100, opacity: 0, fontSize: this.isMobile() == true ? "20px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
      else {
        blurDiv_1.classList.remove('blur-md');

        gsap.fromTo(".title-animation_1",
          { y: -30, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "20px" : "30px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
        gsap.fromTo(".content-animation_1",
          { y: 0, opacity: 1, fontSize: this.isMobile() == true ? "16px" : "20px"}, // 시작 값
          {
            y: 100, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "20px" : "20px",
            opacity: 0, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
    },
    isOpen() {
      this.isClicked = !this.isClicked;
      const blurDiv = this.$refs.blurDiv;

      if (this.isClicked == true) {
        blurDiv.classList.add('blur-md');

        gsap.fromTo(".title-animation",
          { y: 0, opacity: 0, fontSize: this.isMobile() == true ? "20px" : "30px"}, // 시작 값
          {
            y: -30, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );

        gsap.fromTo(".content-animation",
          { y: 100, opacity: 0, fontSize: this.isMobile() == true ? "20px" : "20px" },  // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "16px" : "20px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.3, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
      else {
        blurDiv.classList.remove('blur-md');

        gsap.fromTo(".title-animation",
          { y: -30, opacity: 0, fontSize: this.isMobile() == true ? "16px" : "20px" }, // 시작 값
          {
            y: 0, // 종료 위치 (y축으로 100px 위로)        
            fontSize: this.isMobile() == true ? "20px" : "30px",
            opacity: 1, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
        gsap.fromTo(".content-animation",
          { y: 0, opacity: 1, fontSize: this.isMobile() == true ? "16px" : "20px"}, // 시작 값
          {
            y: 100, // 종료 위치 (y축으로 100px 위로)        
            fontSize:  this.isMobile() == true ? "20px" : "20px",
            opacity: 0, // 종료 투명도 (완전 불투명)
            duration: 0.5, // 애니메이션 지속 시간 1초
            ease: "power1.out", // 부드러운 애니메이션 효과
          }
        );
      }
    },
  }
}
</script>