<template>
  <div>
    <ul>
      <li class="flex items-center">2023
        <div class="w-3 h-3 ml-5 rounded-full bg-emerald-500 hover:cursor-pointer hover:border-[10px]  hover:bg-red-900">
        </div>        
      </li>
      <li>2022</li>
      <li>2021</li>
    </ul>
  </div>
</template>
<script>
export default {
  
}
</script>