<template>
  <Header />

  <div class="lg:pt-24 lg_tall:pt-24 sm:pt-10 mx-auto w-full">

    <!-- 문의사항 -->
    <Qna class="lg:py-24 lg_tall:py-24 sm:py-16" :qnaData="qnaData" />
    <!-- 자주묻는 질문 -->
    <div class="font-basic text-text-0 bg-slate-50 pt-20 lg:px-[18%] lg_tall:px-[18%] sm:px-[10%] lg:py-24 lg_tall:py-24 sm:py-16">
      <p class="lg:text-title-size lg_tall:text-title-size sm:text-medium-size font-bold">자주 묻는 질문</p>
      <!-- 탭 -->
      <div class="flex space-x-4 mb-4 mt-10">
        <button v-for="(tab, index) in tabs" :key="index"
          :class="{ '': currentTab !== tab, 'bg-main-0 text-white': currentTab === tab }" class="px-4 py-2 rounded"
          @click="tab !== 'Q-Meet Platform' && (currentTab = tab)">
          <template v-if="tab === 'Q-Meet Platform'">
            <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-content-size">QMeet(오픈 준비중)</p>
            <!-- <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-[11px]">(오픈 준비중)</p> -->
          </template>
          <p class="lg:text-medium-size lg_tall:text-medium-size sm:text-content-size" v-html="tab" v-else>
          </p>
        </button>
      </div>

      <!-- 질문 목록과 선택된 질문 내용 -->
      <div class="space-y-6 text-content-size mt-10">
        <div v-for="(item, index) in filteredList" :key="index" class="cursor-pointer p-2 hover:bg-gray-100 rounded">
          <p class="" @click="toggleSelection(item)">
            Q. {{ item.question }}
          </p>
          <!-- 답변 내용이 질문 바로 아래에 나타남 -->
          <div v-if="selectedItem === item" class="mt-2 p-2 bg-gray-100 rounded">
            <p>{{ item.answer }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script>

import Header from '@/components/layoutComponents/Header.vue'
import Footer from '@/components/layoutComponents/Footer.vue'
import Qna from '@/components/baseComponents/Qna.vue'

export default {
  components: { Header, Footer, Qna },

  data() {
    return {
      qnaData: {
        isShowplatform: true,
        smallTitle: '문의하기',
        title: '지금바로 문의해 주세요.',
        detailList: ['✔️ 평일 2일 내, 답변 드립니다.',
        '✔️ 문의하실 플랫폼을 선택 해 주세요.', '✔️ 필요시 세부정보를 이메일로 보내드립니다. (서비스 제공, 솔루션 문의 등)'
      , '✔️ 전화 상담을 원하신다면 02-2085-0606로 연락 부탁드립니다.']
      },

      currentTab: 'Geteyes Platform',
      tabs: ['Geteyes Platform', 'Q-Meet Platform'],
      questions: [
        { category: 'Testing Service', question: '프로젝트팀과의 협업은 어떻게 이루어지고 있나요?', answer: 'A. 프로젝트팀과는 주기적인 미팅을 통해 협업을 강화하고 있습니다. 변경사항에 대한 테스트 계획 및 스케줄 조정 등 긴밀한 협조를 통해 이루어지고 있습니다.' },
        { category: 'Testing Service', question: '팀 구성원들에게 어떻게 교육과 훈련을 제공하고 있나요?', answer: 'A. 팀 구성원들에게는 주기적으로 교육 및 훈련 세션을 제공하여 최신 도구와 기술에 대한 이해를 강화하고 있습니다. 또한 정기적인 회의를 통해 소통을 강화하고 있습니다.' },
        { category: 'Testing Service', question: '품질 지표는 어떻게 측정되고 있나요?', answer: 'A. 주간 및 월간 보고서를 통해 품질 지표를 모니터링하고 있으며, 테스트 커버리지, 결함 추적률, 테스트 통과율 등을 중점적으로 측정합니다.' },
        { category: 'Testing Service', question: '보안 및 규정 준수는 어떻게 고려되고 있나요?', answer: 'A. 보안은 품질 보증 프로세스의 핵심 부분으로 고려되고 있으며, 규정 준수를 위한 CHECK-LIST를 통해 교육을 하고 있습니다.' },
        { category: 'Testing Service', question: '이전 프로젝트에서 어떤 성과를 이뤄냈나요?', answer: 'A. 60개 이상의 프로젝트 수행 이력이 있고,  고객사 30곳 이상 확보 하였습니다.' },

        { category: 'Geteyes Platform', question: '[로그인]아이디와 비밀번호가 기억나지 않아요.', answer: 'A. 앱 실행 후 로그인 페이지로 이동하셔서 아이디/ 비밀번호 찾기를 이용해주세요. 해결이 되지 않는다면 마이페이지 > 1:1문의 를 통해 문의 바랍니다. 순차적으로 답변드리도록 하겠습니다.' },
        { category: 'Geteyes Platform', question: '[결제] 이미 주문을 했는데 결제 수단을 변경할 수 있나요?', answer: 'A. 주문이 완료되면 결제 수단 변경이 어렵습니다. 꼭 변경하셔야 한다면 기존 주문은 취소하고 다른 결제 수단을 선택하셔서 새로 주문해 주세요.' },
        { category: 'Geteyes Platform', question: '[주문취소] 선물한 배송상품의 주문을 취소할 수 있나요?', answer: 'A. 주문이 선물받으신 분께서 배송지 입력/확정을 하지 않은 배송지 미입력 상태인 경우, 선물하신 분께서 직접 취소할 수 있습니다.' },
        { category: 'Geteyes Platform', question: '[주문취소] 선물한 모바일 교환권의 주문을 취소할 수 있나요?', answer: 'A. 모바일 교환권을 사용하지 않은 상태라면 선물하신 분께서 구매자 취소 가능 기간(90일)까지 직접 주문 취소 및 결제 금액의 100%를 환불받으실 수 있습니다.※ 구매자 취소 가능 기간(90일)이 지난 상태라면 선물받으신 분께서 유효기간 안에 사용하거나 취소 및 결제 금액의 90%를 환불받으실 수 있습니다.' },
        { category: 'Geteyes Platform', question: '[주문취소] 카드 취소 시 언제 확인이 가능한가요?', answer: 'A. 결제 당일 오후 5시 이전 카드 취소는 당일 확인 가능하며 결제 다음날 이후부터는 영업일 기준 3~5일이 걸리며 자동으로 SMS가 발송됩니다.' },
        { category: 'Geteyes Platform', question: '[배송상품] 선물받은 상품의 옵션을 변경하고 싶어요.', answer: 'A. 겟아이즈 내 아직 옵션 선택을 변경할 수 있는 기능이 도입되지 않았습니다.  다른 옵션을 원하신다면 배송지를 입력하신 후 마이페이지>1:1문의를 통해 알려주시면 옵션 변경을 도와드리겠습니다.출고 이후 연락 시 옵션 변경은 어렵습니다.' },
        { category: 'QMeet', question: '문의 사항1', answer: '답변1' },
        { category: 'QMeet', question: '문의 사항2', answer: '답변2' },
        { category: 'QMeet', question: '문의 사항3', answer: '답변3' },
        // ... 다른 질문들
      ],
      selectedItem: null,
      form: {
        companyName: '',
        monthlyTransaction: '',
        agreement: false,
      },
    };
  },
  methods: {
    toggleSelection(item) {
      this.selectedItem = this.selectedItem === item ? null : item;
    },
  },
  computed: {
    filteredList() {
      return this.questions.filter((q) => q.category === this.currentTab);
    },
  },
};
</script>
