<template>
  <div class="flex-col mx-auto">
    <img class="items-center" :src="imgUrl" alt="">    
      <p class="font-bold text-main-0 text-4xl text-center">{{ title }}</p>
      <p class="mt-4 text-center">{{ content }}</p>    
  </div>
</template>
<script>
export default {
  props: ['imgUrl', 'title', 'content']
}
</script>