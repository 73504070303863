<template>
  <div class="flex flex-col px-4 mt-10">    
      <i class="fa-4x"
      :class="imgSrc"></i>    
    <img class="w-[60px] h-[60px] hidden" :src="imgSrc" alt="">
    <p class="mt-10 text-medium-size text-main-0 font-semibold">{{ title }}</p>
    <p class="mt-5 text-content-size">{{ content }}</p>
    <div class="mt-5 border-t border-gray-300"></div>
  </div>
</template>
<script>
export default {
  props: {
    imgSrc: String,
    title: String,
    content: String,
  },
}
</script>
<style lang="">
  
</style>